import { FinanceApplicationsMerchantReportModel } from '../../models/finance-applications-merchant-report.model';
import { FinanceApplicationsMerchantReportUiModel } from '../../ui-models/finance-applications-merchant-report.ui-model';

function mapToUi(
  financeApplicationsMerchantReport: FinanceApplicationsMerchantReportModel
): FinanceApplicationsMerchantReportUiModel {
  return {
    ...financeApplicationsMerchantReport
  };
}

function mapToUiList(
  financeApplicationsMerchantReportList: FinanceApplicationsMerchantReportModel[]
): FinanceApplicationsMerchantReportUiModel[] {
  return financeApplicationsMerchantReportList.map(mapToUi);
}

const financeApplicationsMerchantReportMapper = { mapToUi, mapToUiList };

export default financeApplicationsMerchantReportMapper;
