import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

const StyledTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '1.8rem',
  fontWeight: 700,
  lineHeight: '2.5rem',
  color: theme.palette.text.primary,
  marginBottom: '24px'
}));

export { StyledTitle };
