// @ts-nocheck
import { ThemeOptions } from '@mui/material';

export default function RangeDatePicker(theme: ThemeOptions) {
  return {
    root: {
      width: '100%',

      '& .rdrDateDisplayWrapper': {
        cursor: 'pointer'
      },

      '& .rdrMonths, & .rdrDateInput': {
        pointerEvents: 'none'
      },

      '&.collapsed': {
        '& .rdrMonthAndYearWrapper, & .rdrMonths': {
          display: 'none'
        }
      }
    }
  };
}
