/**
 * UserRole enum to define survey types
 */
export enum UserRole {
  SUPER_ADMIN = 'superAdmin',
  DIGITAL_SALES_AGENT = 'digitalSalesAgent',
  DIGITAL_SALES_SENIOR_AGENT = 'digitalSalesSeniorAgent',
  DIGITAL_SALES_LEAD = 'digitalSalesLead',
  DIGITAL_SALES_MANAGER = 'digitalSalesManager',
  RETAIL_SALES_AGENT = 'retailSalesAgent',
  RETAIL_SALES_SENIOR_AGENT = 'retailSalesSeniorAgent',
  RETAIL_SALES_LEAD = 'retailSalesLead',
  RETAIL_SALES_MANAGER = 'retailSalesManager',
  CORPORATE_SALES_AGENT = 'corporateSalesAgent',
  CORPORATE_SALES_SENIOR_AGENT = 'corporateSalesSeniorAgent',
  CORPORATE_SALES_LEAD = 'corporateSalesLead',
  CORPORATE_SALES_MANAGER = 'corporateSalesManager',
  FINANCE_OFFICER = 'financeOfficer',
  E_COMMERCE_AUTO = 'eCommerceAuto',
  E_COMMERCE_FINANCE = 'eCommerceFinance',
  E_COMMERCE_MANAGER = 'eCommerceManager',
  OPERATION_MANAGER = 'operationManager',
  FINANCE_MERCHANT_AGENT = 'financeMerchantAgent',
  FINANCE_MERCHANT_MANAGER = 'financeMerchantManager',
  FINANCE_MERCHANT_ADMIN = 'financeMerchantAdmin',
  ADMIN = 'admin',
  GUEST = 'guest'
}

export enum FinanceMerchantRoles {
  FINANCE_MERCHANT_AGENT = 'financeMerchantAgent',
  FINANCE_MERCHANT_MANAGER = 'financeMerchantManager',
  FINANCE_MERCHANT_ADMIN = 'financeMerchantAdmin'
}
