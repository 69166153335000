import { ReactComponent as DashboardLogo } from '../../assets/svgs/side-bar-icons/dashboard.icon.svg';
import { ReactComponent as ActiveDashboardLogo } from '../../assets/svgs/side-bar-icons/dashboard-active.icon.svg';
import { ReactComponent as PartnersLogo } from '../../assets/svgs/side-bar-icons/partners.icon.svg';
import { ReactComponent as ActivePartnersLogo } from '../../assets/svgs/side-bar-icons/partners-active.icon.svg';
import { ReactComponent as OperationsLogo } from '../../assets/svgs/side-bar-icons/operations.icon.svg';
import { ReactComponent as ActiveOperationsLogo } from '../../assets/svgs/side-bar-icons/operations-active.icon.svg';
// import { ReactComponent as PaymentsLogo } from '../../assets/svgs/side-bar-icons/payments.icon.svg';
// import { ReactComponent as ActivePaymentsLogo } from '../../assets/svgs/side-bar-icons/payments-active.icon.svg';
import { ReactComponent as ProductsLogo } from '../../assets/svgs/side-bar-icons/products.icon.svg';
import { ReactComponent as ActiveProductsLogo } from '../../assets/svgs/side-bar-icons/products-active.icon.svg';
import { ReactComponent as CustomersLogo } from '../../assets/svgs/side-bar-icons/customers.icon.svg';
import { ReactComponent as ActiveCustomersLogo } from '../../assets/svgs/side-bar-icons/customers-active.icon.svg';
import { ReactComponent as ReportsLogo } from '../../assets/svgs/side-bar-icons/reports.icon.svg';
import { ReactComponent as ActiveReportsLogo } from '../../assets/svgs/side-bar-icons/reports-active.icon.svg';
import { ReactComponent as SalesLogo } from '../../assets/svgs/side-bar-icons/sales-management.icon.svg';
import { ReactComponent as ActiveSalesLogo } from '../../assets/svgs/side-bar-icons/sales-management-active.icon.svg';
import { ReactComponent as ActiveCommunicationLogo } from '../../assets/svgs/side-bar-icons/communication-active.icon.svg';
import { ReactComponent as CommunicationLogo } from '../../assets/svgs/side-bar-icons/communication.icon.svg';
import { ReactComponent as SettingsLogo } from '../../assets/svgs/side-bar-icons/settings.icon.svg';
import { ReactComponent as ActiveSettingsLogo } from '../../assets/svgs/side-bar-icons/settings-active.icon.svg';

import { MenuItems } from '../enums/side-menu.enum';
import { SideMenuIconProps } from '../types/shared-types/side-menu.type';

export type MenuIconsProps = {
  [key in MenuItems]: SideMenuIconProps;
};

export const MenuIcons: MenuIconsProps = {
  DASHBOARD_MODULE: { logo: DashboardLogo, activeLogo: ActiveDashboardLogo },
  [MenuItems.LEADS]: { logo: CustomersLogo, activeLogo: ActiveCustomersLogo },
  [MenuItems.CUSTOMERS]: { logo: CustomersLogo, activeLogo: ActiveCustomersLogo },
  [MenuItems.OPERATIONS]: { logo: OperationsLogo, activeLogo: ActiveOperationsLogo },
  // PAYMENTS_MODULE: { logo: PaymentsLogo, activeLogo: ActivePaymentsLogo },
  [MenuItems.PARTNERS]: { logo: PartnersLogo, activeLogo: ActivePartnersLogo },
  [MenuItems.PRODUCTS]: { logo: ProductsLogo, activeLogo: ActiveProductsLogo },
  [MenuItems.REPORTS]: { logo: ReportsLogo, activeLogo: ActiveReportsLogo },
  [MenuItems.USERS]: { logo: CustomersLogo, activeLogo: ActiveCustomersLogo },
  [MenuItems.COMMUNICATION]: { logo: CommunicationLogo, activeLogo: ActiveCommunicationLogo },
  [MenuItems.SALES]: { logo: SalesLogo, activeLogo: ActiveSalesLogo },
  [MenuItems.SETTINGS]: { logo: SettingsLogo, activeLogo: ActiveSettingsLogo }
};
