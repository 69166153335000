import _ from 'lodash';
import { SelectOptionsProps } from '../../../../types/shared-types/select.type';

const convertEnumsToIDLabelArr = (data: { [key: string]: string }) => {
  const convertedData = Object.keys({ ...data }).map((key) => ({
    id: key.toLowerCase(),
    label: _.startCase(data[key])
  }));
  return convertedData;
};

const getIDLabelValue = (data: SelectOptionsProps[], id: string | undefined) => {
  if (id) {
    return data.find((obj) => obj.id === id);
  } else return { id: '', label: '' };
};

export { convertEnumsToIDLabelArr, getIDLabelValue };
