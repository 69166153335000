import { FC } from 'react';
import moment from 'moment-timezone';
import CloseIcon from '@mui/icons-material/Close';
import { Box, SxProps, Typography } from '@mui/material';
import _ from 'lodash';
import Avatar from '@mui/material/Avatar';
import { StyledChip } from './chip.styles';
import { isValidDate } from '../../../services/helpers/ui-helper/ui-helper.service';

export enum ChipColor {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info'
}

export interface ChipProps {
  data: {
    label: string;
    labelValueText?: string;
    image?: string;
  };
  config: {
    chipColor: ChipColor;
    isDisabled?: boolean;
    paddingTop?: string;
    chipHeight?: string;
    chipLineHeight?: string;
    chipFont?: string;
    chipMarginBottom?: string;
    isClickable?: boolean;
  };
  eventHandlers?: {
    onDelete?: (key: string, value: string) => void;
    onClick?: () => void;
  };
}

const Chip: FC<ChipProps> = ({ data, config, eventHandlers = { onDelete: undefined } }) => {
  const { label } = data;
  const {
    isDisabled,
    chipColor,
    paddingTop,
    chipHeight,
    chipLineHeight,
    chipFont,
    chipMarginBottom,
    isClickable
  } = config;
  const { onDelete, onClick } = eventHandlers;

  let { labelValueText = '' } = data;
  labelValueText = isValidDate(labelValueText)
    ? moment(labelValueText).format('DD/MM/YYYY')
    : labelValueText?.replaceAll('_', ' ');

  const ChipLabel: FC = () => {
    return (
      <Box
        sx={{ display: 'flex', cursor: isClickable ? 'pointer' : 'auto', alignItems: 'center' }}
        onClick={() => {
          if (isClickable && onClick) {
            onClick();
          }
        }}
      >
        {data.image && (
          <Avatar
            variant="circular"
            sx={{
              p: '0.1px',
              border: '1px solid #E0E0E0',
              borderRadius: '10px',
              mr: 0.2,
              width: 20,
              height: 20,
              backgroundColor: 'white'
            }}
          >
            <img
              style={{ objectFit: 'cover', width: '100%', borderRadius: '20px', height: '100%' }}
              src={data.image}
            />
          </Avatar>
        )}
        <Typography
          component="h5"
          variant="h6"
          sx={{
            textTransform: 'capitalize'
          }}
        >
          {label ? `${_.startCase(label)}${labelValueText ? ':' : ''}` : '--'}
        </Typography>

        <Typography
          variant="h6"
          sx={{
            textTransform: 'capitalize'
          }}
        >
          {labelValueText ? `${_.startCase(labelValueText)}` : ''}
        </Typography>
      </Box>
    );
  };

  return (
    <StyledChip
      label={<ChipLabel />}
      chipColor={chipColor}
      isDisabled={isDisabled}
      paddingTop={paddingTop}
      paddingLeft={data.image ? '0' : undefined}
      chipHeight={chipHeight}
      chipLineHeight={chipLineHeight}
      chipFont={chipFont}
      chipMarginBottom={chipMarginBottom}
      onDelete={onDelete ? () => onDelete(label, labelValueText) : undefined}
      deleteIcon={<CloseIcon />}
    />
  );
};

export default Chip;
