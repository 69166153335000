import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

export interface StyledDrawerProps extends DrawerProps {
  width: number;
  appearOverAppbar?: boolean;
}

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'appearOverAppbar'
})<StyledDrawerProps>(({ width, appearOverAppbar, theme }) => ({
  zIndex: appearOverAppbar ? theme.zIndex.modal : theme.zIndex.mobileStepper,
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    border: 'none',
    width,
    filter: theme.customShadows.sideMenu,
    zIndex: appearOverAppbar ? theme.zIndex.modal : theme.zIndex.mobileStepper,
    [theme.breakpoints.down('sm')]: {
      width: width === 256 ? width : width / 1.5
    }
  }
}));

export { StyledDrawer };
