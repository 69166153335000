import { ErrorInfo } from '../../../interfaces/error-info.interface';
import { ApiResponse } from 'apisauce';
import { PUBLIC_PATH } from '../../../config/routes-config';
import { ResponseModel } from '../../../interfaces/response-model.interface';
import { FinanceApplicationsOverviewReportModel } from '../../../models/finance-applications-overview-report.model';
import { FinanceApplicationsFrequencyReportModel } from '../../../models/finance-applications-frequency-report.model';
import { UserModel } from '../../../models/user.model';
import { FinanceApplicationsAgentReportModel } from '../../../models/finance-applications-agent-report.model';
import {
  ApplicationReportStatusEnum,
  FinAppReportStatus
} from '../../../../Modules/CustomersModule/shared/enums/customer-finance-application.enum';
import { FinanceApplicationsMerchantReportModel } from '../../../models/finance-applications-merchant-report.model';

export default {
  // Functions return fixtures

  overviewReport: (
    startDate: string,
    endDate: string,
    financialMerchantId?: string[],
    assignedTo?: string[]
  ): Promise<ApiResponse<ResponseModel<FinanceApplicationsOverviewReportModel>, ErrorInfo>> => {
    const autoProducts = fetch(PUBLIC_PATH + 'mocks/post-lead_comment/default.json');
    return autoProducts.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<FinanceApplicationsOverviewReportModel>, ErrorInfo>;
    });
  },

  performanceReport: (
    startDate: string,
    endDate: string,
    applicationStatus: ApplicationReportStatusEnum,
    financialMerchantId?: string[],
    agentId?: string[]
  ): Promise<
    ApiResponse<ResponseModel<{ [key: string]: FinanceApplicationsAgentReportModel }>, ErrorInfo>
  > => {
    const autoProducts = fetch(PUBLIC_PATH + 'mocks/post-lead_comment/default.json');
    return autoProducts.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<
        ResponseModel<{ [key: string]: FinanceApplicationsAgentReportModel }>,
        ErrorInfo
      >;
    });
  },
  merchantReport: (
    startDate: string,
    endDate: string,
    status: ApplicationReportStatusEnum
  ): Promise<ApiResponse<ResponseModel<FinanceApplicationsMerchantReportModel[]>, ErrorInfo>> => {
    const autoProducts = fetch(PUBLIC_PATH + 'mocks/post-lead_comment/default.json');
    return autoProducts.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<FinanceApplicationsMerchantReportModel[]>, ErrorInfo>;
    });
  },
  getAllAgents: (): Promise<ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>> => {
    const autoProducts = fetch(PUBLIC_PATH + 'mocks/get-lead_agents/default.json');
    return autoProducts.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>;
    });
  },

  frequencyReport: (
    frequency: 'daily' | 'monthly' | 'yearly',
    metric: 'amount' | 'count',
    startDate: string,
    endDate: string,
    financialMerchantId?: string[],
    assignedTo?: string[]
  ): Promise<ApiResponse<ResponseModel<FinanceApplicationsFrequencyReportModel>, ErrorInfo>> => {
    const autoProducts = fetch(PUBLIC_PATH + 'mocks/post-lead_comment/default.json');
    return autoProducts.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<FinanceApplicationsFrequencyReportModel>, ErrorInfo>;
    });
  }
};
