import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '../../../../../shared/config/routes-config';
import { UserRole } from '../../../../../shared/enums/user-role.enum';
import { RouteConfig } from '../../../../../shared/interfaces/routes-config.interface';
import DefaultLayout from '../../../../../shared/layouts/default/default.layout';

const ProductsListContainer = React.lazy(
  () => import('../../containers/products-list/products-list.container')
);

const ProductAddUsedAutoContainer = React.lazy(
  () => import('../../containers/products-add-auto/products-add-auto.container')
);

const ProductEditUsedAutoContainer = React.lazy(
  () => import('../../containers/products-edit-auto/products-edit-auto.container')
);

export const ProductsRoutesConfig: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={(APP_ROUTES.PRODUCTS_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH} />
  },
  {
    path: (APP_ROUTES.PRODUCTS_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[UserRole.E_COMMERCE_AUTO, UserRole.E_COMMERCE_MANAGER, UserRole.ADMIN]}
          component={ProductsListContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.PRODUCTS_MODULE.CHILDREN as RouteConfig).ADD_AUTO.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          addLayout
          authenticationRequired={true}
          permittedList={[UserRole.E_COMMERCE_AUTO, UserRole.E_COMMERCE_MANAGER, UserRole.ADMIN]}
          component={ProductAddUsedAutoContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.PRODUCTS_MODULE.CHILDREN as RouteConfig).EDIT_AUTO.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          addLayout
          authenticationRequired={true}
          permittedList={[UserRole.E_COMMERCE_AUTO, UserRole.E_COMMERCE_MANAGER, UserRole.ADMIN]}
          component={ProductEditUsedAutoContainer}
        />
      </React.Suspense>
    )
  }
];
