import { createLogger } from 'redux-logger';
import { ActionType } from 'typesafe-actions';

export type LoggerAction = ActionType<any>;

const actionTransformer = (action: LoggerAction) => {
  if (action.type === 'BATCHING_REDUCER.BATCH') {
    action.payload.type = action.payload.map((next: LoggerAction) => next.type).join(' => ');
    return action.payload;
  }

  return action;
};

const level = 'info';

export default createLogger({
  actionTransformer,
  level
});
