import { FC } from 'react';
import type { Moment } from 'moment-timezone';

import FormLabel from '@mui/material/FormLabel';
import { DateView } from '@mui/x-date-pickers/models';

import DatePicker from '../../../core-ui/controls/date-picker/date-picker.component';
import StyledFormControl from '../../../core-ui/form/styled-components/styled-form-control';
import { StyledDatePickerContainer } from './form-date-picker.styles';

interface FormDatePickerProps {
  data: {
    name: string;
    label: string;
    value: any;
    error?: string;
    helperText?: string;
  };
  config?: {
    views?: DateView[];
    format?: string;
    disableFuture?: boolean;
    isDisabled?: boolean;
    shouldDisableYear?: (year: string | Moment) => boolean;
  };
  eventHandlers: {
    handleInputChange: (e: any) => void;
  };
}
const FormDatePicker: FC<FormDatePickerProps> = ({ data, config = {}, eventHandlers }) => {
  const { name, label, value, error, helperText = ' ' } = data;
  const {
    views = ['year', 'month', 'day'],
    format = 'DD/MM/YYYY',
    disableFuture,
    isDisabled,
    shouldDisableYear
  } = config;
  const { handleInputChange } = eventHandlers;

  return (
    <StyledFormControl>
      <FormLabel>{label}</FormLabel>
      <StyledDatePickerContainer>
        <DatePicker
          data={{ value, error, helperText, name }}
          config={{ views, format, disableFuture, isDisabled, shouldDisableYear }}
          eventHandlers={{ handleInputChange }}
        />
      </StyledDatePickerContainer>
    </StyledFormControl>
  );
};

export default FormDatePicker;
