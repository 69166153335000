import { UserAuthModel } from '../models/user-auth.model';
import { UserAuthUIModel } from '../ui-models/user-auth.ui-model';

function mapToUI(userData: UserAuthModel): UserAuthUIModel {
  return {
    accessToken: userData.data.accessToken,
    refreshToken: userData.data.refreshToken,
    tokenExpiresIn: userData.data.tokenExpiresIn,
    email: userData.data.email,
    image: userData.data.image,
    userId: userData.data.userId,
    role: userData.data.role,
    name: userData.data.name || ''
  };
}

export default {
  mapToUI
};
