import { MerchantModel } from '../../models/merchant.model';
import { CompanyDetailsUIModel } from '../../ui-models/merchants.ui-model';

function mapToUI(merchantAutoTableData: MerchantModel): CompanyDetailsUIModel {
  return {
    id: merchantAutoTableData.id,
    createdAt: merchantAutoTableData.createdAt,
    companyNameEn: merchantAutoTableData.companyName?.en,
    companyLogo: {
      id: merchantAutoTableData.key ?? '',
      url: merchantAutoTableData.url ?? ''
    } as unknown as { file: string; dataURL: string }[],
    productsCount: merchantAutoTableData.productsCount,
    companyStatus: merchantAutoTableData.companyStatus,
    isFeatured: merchantAutoTableData.isFeatured,
    companyLegalNameEn: merchantAutoTableData.legalName?.en,
    merchantCityEn: merchantAutoTableData.merchantCity?.en,
    merchantAreaEn: merchantAutoTableData.merchantArea?.en
  };
}

const merchantsAutoTableMapper = { mapToUI };

export default merchantsAutoTableMapper;
