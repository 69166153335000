import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../interfaces/error-info.interface';
import api from '../../config/http.config';
import {
  BranchDataModel,
  BranchesModel,
  CompanyContactPersonModel,
  CompanyDetailsModel,
  GetMerchantDocumentsModel,
  MerchantDocumentsDataModel,
  MerchantModel,
  MerchantUserModel,
  MerchantUsersModel
} from '../../models/merchant.model';
import { ResponseModel } from '../../interfaces/response-model.interface';
import {
  DeletedMerchantDocPayload,
  MerchantTablePayload,
  MerchantUsersPayload
} from '../../../Modules/MerchatsModule/shared/types/merchant-auto-payload.type';

const apis = {
  // create merchant data
  createCompanyDetails: (
    companyDetails: CompanyDetailsModel
  ): Promise<ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>> =>
    api.post('/productCompany/main', companyDetails),

  createCompanyLogo: (data: FormData): Promise<ApiResponse<{ message: string }, ErrorInfo>> =>
    api.post('/productCompany/logo', data),

  createCompanyContactPerson: (
    contactPersonDetails: CompanyContactPersonModel
  ): Promise<ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>> =>
    api.post('/productCompany/contactperson', contactPersonDetails),

  createBranchData: (
    branches: BranchesModel
  ): Promise<ApiResponse<ResponseModel<{ ids: string[] }>, ErrorInfo>> =>
    api.post('/productCompanyBranch', branches),

  // get merchant data
  getCompanyById: (id: string): Promise<ApiResponse<ResponseModel<MerchantModel>, ErrorInfo>> =>
    api.get(`/productCompany/${id}`),

  getCompanyBranches: (
    id: string
  ): Promise<ApiResponse<ResponseModel<BranchDataModel[]>, ErrorInfo>> =>
    api.get(`/productCompanyBranch/company/${id}`),

  // update merchant data
  updateCompanyDetails: (
    companyDetails: CompanyDetailsModel
  ): Promise<ApiResponse<ResponseModel<CompanyDetailsModel>, ErrorInfo>> =>
    api.put('/productCompany/main', companyDetails),

  updateCompanyContactPerson: (
    contactPersonDetails: CompanyContactPersonModel
  ): Promise<ApiResponse<ResponseModel<CompanyContactPersonModel>, ErrorInfo>> =>
    api.put('/productCompany/contactperson', contactPersonDetails),

  updateBranchData: (
    branch: BranchDataModel
  ): Promise<ApiResponse<ResponseModel<BranchDataModel>, ErrorInfo>> =>
    api.put('/productCompanyBranch', branch),

  deleteBranch: (id: string): Promise<ApiResponse<{ message: string }, ErrorInfo>> =>
    api.delete(`/productCompanyBranch/${id}`),

  updateCompanyStatus: (
    id: string,
    companyStatus: 'active' | 'disabled'
  ): Promise<ApiResponse<ResponseModel<MerchantModel>, ErrorInfo>> =>
    api.put('/productCompany/status', { id, companyStatus }),

  // get auto-table data
  searchCompanies: (
    searchCompanies: MerchantTablePayload
  ): Promise<ApiResponse<ResponseModel<MerchantModel[]>, ErrorInfo>> =>
    api.post('/productCompany/search', searchCompanies),

  // company users
  createCompanyUser: (
    user: MerchantUserModel
  ): Promise<ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>> =>
    api.post('/productCompany/users', user),

  searchCompanyUsers: (
    searchUser: MerchantUsersPayload
  ): Promise<ApiResponse<ResponseModel<MerchantUsersModel[]>, ErrorInfo>> =>
    api.post('/productCompany/users/search', searchUser),

  deleteCompanyUser: (userId: string): Promise<ApiResponse<{ message: string }, ErrorInfo>> =>
    api.delete(`/productCompany/users/${userId}`),

  // merchant documents
  createMerchantDocuments: (
    merchantDocuments: FormData
  ): Promise<ApiResponse<{ message: string }, ErrorInfo>> =>
    api.post('/productCompany/upload', merchantDocuments, {
      headers: { Accept: 'multipart/form-data' }
    }),

  getMerchantDocumentsById: (
    merchantId: string
  ): Promise<ApiResponse<ResponseModel<GetMerchantDocumentsModel[]>, ErrorInfo>> =>
    api.get(`/productCompany/documents/${merchantId}`),

  deleteMerchantDocument: (
    document: DeletedMerchantDocPayload
  ): Promise<ApiResponse<ResponseModel<MerchantDocumentsDataModel>, ErrorInfo>> =>
    api.delete('/productCompany/document', {}, { data: document })
};

// TODO: Enhance the way of mocking APIs
export const merchantApi = apis;
// export const merchantApi = debugConfig.useFixtures ? userApiFixtureService : apis;
