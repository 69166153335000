import { FinanceApplicationsFrequencyReportModel } from '../../models/finance-applications-frequency-report.model';
import { FinanceApplicationsFrequencyReportUiModel } from '../../ui-models/finance-applications-frequency-report.ui-model';

function mapToUi(
  financeApplicationsMonthlyReport: FinanceApplicationsFrequencyReportModel
): FinanceApplicationsFrequencyReportUiModel {
  return {
    ...financeApplicationsMonthlyReport
  };
}

const financeApplicationsMonthlyReportMapper = { mapToUi };

export default financeApplicationsMonthlyReportMapper;
