import React, { useEffect, useState } from 'react';
import { getMenuList } from '../../../../services/routes/routes.service';
import { MenuConfigItem } from '../../../../interfaces/side-menu-item.interface';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import MenuItem from './menu-item';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers';

const SideMenuContent = () => {
  const theme = useTheme();
  const [MenuItems, setMenuItems] = useState<MenuConfigItem[]>([]);
  const userRole = useSelector((state: RootState) => state.auth.role);

  useEffect(() => {
    const MenuItemsList = getMenuList(userRole);
    setMenuItems(MenuItemsList);
  }, []);

  return (
    <List
      component="nav"
      sx={{
        p: '0px 10px',
        pt: { xs: '7rem' }
      }}
    >
      {MenuItems.map((item, key) => {
        return <MenuItem item={item} key={key} />;
      })}
    </List>
  );
};

export default SideMenuContent;
