// @ts-nocheck
import { ThemeOptions } from '@mui/material';

export default function ToolBar(theme: ThemeOptions) {
  return {
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '100%',
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
          paddingTop: theme.spacing(2.75),
          paddingBottom: theme.spacing(2.75)
        }
      }
    }
  };
}
