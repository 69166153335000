import MuiFormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';

const StyledFormControl = styled(MuiFormControl)(({ theme }) => ({
  display: 'flex',
  marginBottom: '8px',
  '& .MuiFormLabel-root': {
    color: theme.palette.grey[500],
    fontSize: '1.6rem',
    fontWeight: 700,
    lineHeight: '2.4rem',
    '&.Mui-focused': {
      color: theme.palette.grey[500]
    },
    '& span': { color: theme.palette.error.main }
  },
  '& .MuiFormControl-root': {
    '& .MuiInputAdornment-root': {
      '& .MuiTypography-root': {
        color: theme.palette.grey[400]
      }
    },
    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: 0
    }
  },
  '& .MuiFormHelperText-root.Mui-error': {
    marginLeft: 0
  }
}));

export default StyledFormControl;
