import { ApiResponse } from 'apisauce';
import { FinanceApplicationReportTablePayload } from '../../../../Modules/ReportsModule/shared/types/finance-application-report-payload.type';
import debugConfig from '../../../config/debug.config';
import api from '../../../config/http.config';
import { ErrorInfo } from '../../../interfaces/error-info.interface';
import { FinanceApplicationReportResponseModel } from '../../../interfaces/response-model.interface';
import { FinanceApplicationsReportModel } from '../../../models/finance-application-report.model';
import financeApplicationsReportFixtureService from '../../fixtures/finance-applications-report/finance-applications-report-api.service';

const apis = {
  searchFinanceApplicationsReport: (
    searchFinanceApplicationReport: FinanceApplicationReportTablePayload
  ): Promise<
    ApiResponse<FinanceApplicationReportResponseModel<FinanceApplicationsReportModel[]>, ErrorInfo>
  > =>
    api.post(
      '/customerApplicationData/financeApplicationCases/search',
      searchFinanceApplicationReport
    ),
  exportFinanceApplicationsReport: (
    exportFinanceApplicationReport: Pick<FinanceApplicationReportTablePayload, 'query' | 'sort'>
  ): Promise<ApiResponse<Blob, ErrorInfo>> =>
    api.post(
      '/customerApplicationData/financeApplicationCases/export',
      exportFinanceApplicationReport,
      { responseType: 'blob' }
    )
};

// TODO: Enhance the way of mocking APIs
export const financeApplicationsReportApi = debugConfig.useFixtures
  ? financeApplicationsReportFixtureService
  : apis;
