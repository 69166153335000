import { PUBLIC_PATH } from '../../../config/routes-config';

export default {
  searchFinanceApplicationsReport: async () => {
    try {
      const response = await fetch(
        `${PUBLIC_PATH}mocks/post-financialApplicationReport/default.json`
      );
      return response.json();
    } catch (error) {
      console.error(error);
    }
  },
  exportFinanceApplicationsReport: async () => {
    try {
      const response = await fetch(
        `${PUBLIC_PATH}mocks/post-financialApplicationReport/default.json`
      );
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
};
