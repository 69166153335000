import React, { FC, useCallback, useState } from 'react';
import debounce from 'lodash/debounce';

import FormLabel from '@mui/material/FormLabel';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';

import Autocomplete, { AutocompleteCloseReason } from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import SelectImage from '../styled-select-componenets/styled-select-image';
import StyledFormControl from '../../../../core-ui/form/styled-components/styled-form-control';
import SearchTextField from '../styled-select-componenets/styled-select-search-text';
import StyledSelectSubLabel from '../styled-select-componenets/styled-sub-label';
import Button, { BtnVariant } from '../../../../core-ui/button/button.component';
import { ChangeEvent } from '../../../../../types/shared-types/change-event.type';
import { StyledIconButton } from '../styled-select-componenets/styled-select-icon-btn';
import { SelectOptionsProps } from '../../../../../types/shared-types/select.type';
import { ReactComponent as Plus } from '../../../../../../assets/svgs/dropdown/add-sign.icon.svg';
import { ReactComponent as ArrowDown } from '../../../../../../assets/svgs/dropdown/down-arrow.icon.svg';
import { ReactComponent as SearchIcon } from '../../../../../../assets/svgs/dropdown/search.icon.svg';
import { PopperComponent, StyledPopper } from '../styled-select-componenets/styled-select-popper';
import { FaCheckCircle } from 'react-icons/fa';

interface SelectWithSearchProps {
  data: {
    name: string;
    label: string;
    placeholder: string;
    searchTextPlaceholder: string;
    selectOptions: SelectOptionsProps[];
    value: SelectOptionsProps | null;
    error: any;
    dataLoading?: boolean;
    searchLoading: boolean;
    noResultsFoundText: string;
  };
  config?: {
    addBtnText?: string;
    isDisabled?: boolean;
    isSearchDisabled?: boolean;
    isVerified?: boolean;
  };
  eventHandlers: {
    handleInputChange: (e: ChangeEvent<SelectOptionsProps | null>) => void;
    setSearchLoading: (loading: boolean) => void;
    setSearchQuery: (searchQuery: string) => void;
    handleAddBtnClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  };
}

const FormSelectWithSearch: FC<SelectWithSearchProps> = ({ data, config = {}, eventHandlers }) => {
  const {
    name,
    label,
    placeholder,
    searchTextPlaceholder,
    value,
    error = null,
    dataLoading = false,
    selectOptions,
    searchLoading,
    noResultsFoundText
  } = data;

  const { addBtnText, isDisabled = false, isSearchDisabled = false, isVerified } = config;

  const { handleInputChange, setSearchLoading, setSearchQuery, handleAddBtnClick } = eventHandlers;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [searchValue, setSearchValue] = useState<string>('');

  const open = Boolean(anchorEl);
  const id = open ? name : undefined;

  const theme = useTheme();

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropDownClose = () => {
    setAnchorEl(null);
    setSearchValue('');
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setSearchLoading(true);
    debouncedSearch(e.target.value);
  };

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setSearchQuery(value);
    }, 1000),
    []
  );

  const getFieldIcon = () => {
    if (dataLoading) return <CircularProgress size={20} style={{ marginRight: '8px' }} />;
    if (value?.id && !isDisabled)
      return (
        <>
          {isVerified && <FaCheckCircle size={20} style={{ color: 'green', marginRight: '8px' }} />}

          <StyledIconButton
            onClick={(event) => {
              event.stopPropagation();
              handleInputChange({ target: { name, value: { id: '', label: '' } } });
            }}
          >
            <CloseIcon fontSize="small" style={{ color: isVerified === false ? 'red' : '' }} />
          </StyledIconButton>
        </>
      );

    return (
      <>
        <ArrowDown />
      </>
    );
  };

  return (
    <React.Fragment>
      <StyledFormControl>
        <FormLabel>{label}</FormLabel>
        <TextField
          name={name}
          value={value?.label}
          placeholder={placeholder}
          onClick={handleDropDownOpen}
          disabled={isDisabled}
          label=""
          fullWidth
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {value?.imageUrl && (
                  <img
                    src={value?.imageUrl}
                    style={{
                      height: 24,
                      width: 24,
                      marginLeft: '8px'
                    }}
                  />
                )}
              </InputAdornment>
            ),
            endAdornment: getFieldIcon()
          }}
          sx={{
            '& .MuiInputBase-input': {
              color: theme.palette.grey[400],
              padding: '17px 0px !important'
            },
            '& .MuiOutlinedInput-root': { border: error && `1px solid ${theme.palette.error.main}` }
          }}
        />
        <FormHelperText error>{error ? error : ' '}</FormHelperText>
        <StyledPopper
          id={id}
          open={open && !isDisabled}
          anchorEl={anchorEl}
          placement="bottom-start"
          style={{
            width: anchorEl ? anchorEl.clientWidth : undefined
          }}
        >
          <ClickAwayListener onClickAway={handleDropDownClose}>
            <Autocomplete
              open
              options={selectOptions}
              getOptionLabel={(option) => (option.label ? option.label : '')}
              filterOptions={(x) => x}
              noOptionsText={noResultsFoundText}
              loading={searchLoading}
              PopperComponent={PopperComponent}
              onClose={(event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
                if (reason === 'escape') {
                  handleDropDownClose();
                }
              }}
              onHighlightChange={(event, newValue, reason) => {
                if (reason === 'touch') {
                  handleInputChange({
                    target: {
                      name,
                      value: {
                        id: newValue?.id ?? '',
                        label: newValue?.label ?? '',
                        subLabel: newValue?.subLabel ?? [],
                        ...(newValue?.imageUrl && { imageUrl: newValue?.imageUrl ?? '' })
                      }
                    }
                  });
                }
              }}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                handleInputChange({
                  target: {
                    name,
                    value: {
                      id: newValue?.id ?? '',
                      label: newValue?.label ?? '',
                      subLabel: newValue?.subLabel ?? [],
                      ...(newValue?.imageUrl && { imageUrl: newValue?.imageUrl ?? '' })
                    }
                  }
                });
                handleDropDownClose();
              }}
              renderOption={(props, option) => {
                return (
                  <li
                    style={
                      value && option.id === value.id
                        ? { backgroundColor: theme.palette.action.hover }
                        : {}
                    }
                    {...props}
                    key={option.id}
                  >
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" alignItems="center">
                        {option?.imageUrl && <SelectImage src={option?.imageUrl ?? ''} />}
                        {option.label}
                      </Box>
                      <StyledSelectSubLabel>{option.subLabel?.join(',')}</StyledSelectSubLabel>
                    </Box>
                  </li>
                );
              }}
              renderInput={(params) => {
                return (
                  <SearchTextField
                    ref={params.InputProps.ref}
                    inputProps={{
                      ...params.inputProps,
                      value: searchValue,
                      disabled: isSearchDisabled
                    }}
                    placeholder={searchTextPlaceholder}
                    variant="standard"
                    autoFocus
                    onChange={onSearch}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: searchLoading ? (
                        <CircularProgress size={20} style={{ marginRight: '8px' }} />
                      ) : null,
                      disableUnderline: true
                    }}
                  />
                );
              }}
            />
          </ClickAwayListener>
          {addBtnText && (
            <Button
              config={{ variant: BtnVariant.TEXT, startIcon: <Plus /> }}
              eventHandlers={{ handleClick: handleAddBtnClick ? handleAddBtnClick : () => null }}
              children={addBtnText}
              styles={{ width: '100%', justifyContent: 'left' }}
            />
          )}
        </StyledPopper>
      </StyledFormControl>
    </React.Fragment>
  );
};

export default FormSelectWithSearch;
