import { FinanceApplicationsAgentReportModel } from '../../models/finance-applications-agent-report.model';
import { FinanceApplicationsAgentReportUiModel } from '../../ui-models/finance-applications-agent-report.ui-model';

function mapToUi(financeApplicationsAgentReportList: {
  [key: string]: FinanceApplicationsAgentReportModel;
}): FinanceApplicationsAgentReportUiModel {
  const totalTemp = { ...financeApplicationsAgentReportList.totalApplications };
  delete financeApplicationsAgentReportList.totalApplications;

  return {
    data: Object.values(financeApplicationsAgentReportList).map(
      (financeApplicationsAgentReport) => ({
        agentId: financeApplicationsAgentReport.agent.id || '',
        agent: { ...financeApplicationsAgentReport.agent, monthlyTarget: 9000000 },
        count: financeApplicationsAgentReport.count,
        financedAmount: financeApplicationsAgentReport.financedAmount
      })
    ),
    totalApplications: totalTemp
  };
}

const financeApplicationsAgentReportMapper = { mapToUi };

export default financeApplicationsAgentReportMapper;
