import { ApiResponse } from 'apisauce';
import { PUBLIC_PATH } from '../../config/routes-config';
import { ErrorInfo } from '../../interfaces/error-info.interface';
import { UserAuthModel } from '../../models/user-auth.model';
import { ResponseModel } from '../../interfaces/response-model.interface';
import { AddUserDataModel, UserModel } from '../../models/user.model';
import { SearchUserModel } from '../store/global-add-user.store';

export default {
  // Functions return fixtures
  getRoot: (): any => {
    const responsePromise = fetch(PUBLIC_PATH + 'mocks/root.json');
    return responsePromise.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      };
    });
  },
  registerUser: (user: AddUserDataModel): Promise<ApiResponse<UserAuthModel, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + 'mocks/user/default-user.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<UserAuthModel, ErrorInfo>;
    });
  },
  loginUser: (email: string, password: string): Promise<ApiResponse<UserAuthModel, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + 'mocks/user/default-user.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();

      return {
        data: responseData,
        ok: true
      } as ApiResponse<UserAuthModel, ErrorInfo>;
    });
  },
  changePassword: (
    token: string,
    password: string
  ): Promise<ApiResponse<UserAuthModel, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + 'mocks/user/default-user.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<UserAuthModel, ErrorInfo>;
    });
  },
  forgotPassword: (password: string): Promise<ApiResponse<{}, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + 'mocks/user/default-user.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<{}, ErrorInfo>;
    });
  },
  validateToken: (): Promise<ApiResponse<{}, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + 'mocks/user/default-user.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<{}, ErrorInfo>;
    });
  },
  refreshToken: (refreshToken: string): Promise<ApiResponse<UserAuthModel, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + 'mocks/user/default-user.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();

      return {
        data: responseData,
        ok: true
      } as ApiResponse<UserAuthModel, ErrorInfo>;
    });
  },

  getAllUsers: (): Promise<ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + 'mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>;
    });
  },
  addUser: (): Promise<ApiResponse<ResponseModel<UserModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + 'mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UserModel>, ErrorInfo>;
    });
  },
  getUserByPhoneNumber: ({
    phone,
    name,
    email,
    role
  }: SearchUserModel): Promise<ApiResponse<ResponseModel<UserModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + 'mocks/post-users_searchOne/default.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UserModel>, ErrorInfo>;
    });
  },
  getUserById: (id: string): Promise<ApiResponse<ResponseModel<UserModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + 'mocks/post-users_searchOne/default.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UserModel>, ErrorInfo>;
    });
  },
  addUnverifiedUser: (
    userData: AddUserDataModel
  ): Promise<ApiResponse<ResponseModel<AddUserDataModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + 'mocks/post-users_addUser/default.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<AddUserDataModel>, ErrorInfo>;
    });
  },

  updateUser: (
    userData: AddUserDataModel
  ): Promise<ApiResponse<ResponseModel<AddUserDataModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + 'mocks/put-users_addUser/default.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<AddUserDataModel>, ErrorInfo>;
    });
  }
};
