import { FC, ReactNode } from 'react';
import { StyledDrawer } from './drawer.styles';
import { SxProps } from '@mui/material';

interface DrawerProps {
  config: {
    isOpen?: boolean;
    width?: number;
    variant?: 'permanent' | 'persistent' | 'temporary';
    anchor?: 'bottom' | 'left' | 'right' | 'top';
    appearOverAppbar?: boolean;
  };
  eventHandlers?: {
    handleClose?: () => void;
  };
  children: ReactNode;
  styles?: SxProps;
}

const Drawer: FC<DrawerProps> = ({ config, eventHandlers = {}, children, styles }) => {
  const {
    isOpen,
    width = 256,
    variant = 'temporary',
    anchor = 'right',
    appearOverAppbar = true
  } = config;
  const { handleClose } = eventHandlers;

  return (
    <StyledDrawer
      width={width}
      appearOverAppbar={appearOverAppbar}
      variant={variant}
      anchor={anchor}
      open={isOpen}
      onClose={handleClose}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      sx={styles}
    >
      {children}
    </StyledDrawer>
  );
};

export default Drawer;
