import { FinanceApplicationsOverviewReportModel } from '../../models/finance-applications-overview-report.model';
import { FinanceApplicationsOverviewReportUiModel } from '../../ui-models/finance-applications-overview-report.ui-model';

function mapToUi(
  financeApplicationsOverviewReport: FinanceApplicationsOverviewReportModel
): FinanceApplicationsOverviewReportUiModel {
  return {
    ...financeApplicationsOverviewReport
  };
}

const financeApplicationsOverviewReportMapper = { mapToUi };

export default financeApplicationsOverviewReportMapper;
