import { ThemeOptions } from '@mui/material/styles';

// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

const Typography = (fontFamily: string) => ({
  htmlFontSize: 10,
  fontFamily,
  fontWeightLight: 300,
  fontWeightRegular: 500,
  fontWeightMedium: 600,
  fontWeightBold: 800,
  h1: {
    fontWeight: 800,
    fontSize: '2.4rem',
    lineHeight: '3.3rem'
  },
  h2: {
    fontWeight: 600,
    fontSize: '2.2rem',
    lineHeight: 1.27
  },
  h3: {
    fontWeight: 800,
    fontSize: '2rem',
    lineHeight: '2.2rem'
  },
  h4: {
    fontWeight: 600,
    fontSize: '1.8rem',
    lineHeight: '1.4rem'
  },
  h5: {
    fontWeight: 800,
    fontSize: '1.6rem',
    lineHeight: '2.2rem'
  },
  h6: {
    fontWeight: 600,
    fontSize: '1.4rem',
    lineHeight: '1.9rem'
  },
  h7: {
    letterSpacing: '0.15px'
    // color: theme.palette.text.primary
  },
  // used in DatePicker
  caption: {
    fontWeight: 400,
    fontSize: '1.4rem',
    lineHeight: 1.66,
    lineSpacing: '0.4px'
  },
  // used in DatePicker
  body1: {
    fontSize: '1.6rem',
    lineHeight: 1.57
  },
  body2: {
    fontSize: '0.75rem',
    lineHeight: 1.66
  },
  subtitle1: {
    fontSize: '1.6rem',
    fontWeight: 400,
    lineHeight: '2.2rem'
  },
  subtitle2: {
    fontSize: '1.6rem',
    fontWeight: 400,
    lineHeight: '2.2rem'
  },
  overline: {
    lineHeight: 1.66
  },
  button: {
    textTransform: 'none' as const,
    fontFamily: 'Avenir',
    fontSize: '1.6rem',
    fontWeight: 800,
    lineHeight: '2.2rem'
  }
  // caption: {
  //   letterSpacing: '0.4px'
  //   // color: theme.palette.text.secondary
  // }
  // poster: {              >> the commented code is for adding additional variants
  //   fontSize: '4rem',
  //   color: 'red'
  // }
});

export default Typography;

// declare module '@mui/material/styles' {
//   interface TypographyVariants {
//     poster: React.CSSProperties;
//   }

//   // allow configuration using `createTheme`
//   interface TypographyVariantsOptions {
//     poster?: React.CSSProperties;
//   }
// }

// // Update the Typography's variant prop options
// declare module '@mui/material/Typography' {
//   interface TypographyPropsVariantOverrides {
//     poster: true;
//   }
// }
