import AppBar, { AppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

export const AppBarHeader = styled(AppBar)<AppBarProps>(({ theme }) => ({
  height: 'auto !important',
  '& .MuiToolbar-root': {
    height: '5rem',
    padding: '0 2rem 0 1rem'
  }
}));
