import { FC, ReactNode } from 'react';
import { SxProps } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

export enum BtnVariant {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
  TEXT = 'text'
}

export enum BtnSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export enum BtnColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning'
}
export interface CustomButtonProps {
  config: {
    variant?: BtnVariant;
    size?: BtnSize;
    color?: BtnColor;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    disabled?: boolean;
    loading?: boolean;
  };
  eventHandlers: { handleClick: (e: React.MouseEvent<HTMLElement>) => void };
  children: ReactNode;
  styles?: SxProps;
}

const Button: FC<CustomButtonProps> = ({ config, eventHandlers, children, styles }) => {
  const { handleClick } = eventHandlers;
  const {
    size = 'large',
    variant = 'contained',
    color = 'primary',
    disabled = false,
    loading = false,
    startIcon,
    endIcon
  } = config;

  return (
    <LoadingButton
      onMouseDown={handleClick}
      color={color}
      size={size}
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      loading={loading}
      sx={styles}
    >
      {children}
    </LoadingButton>
  );
};

export default Button;
