import React from 'react';
import styles from './button.module.scss';
import { trackEvent } from '../../../services/utils/tracking/tracking.service';

/**
 * ButtonType represents different button types (success, warning, danger, default)
 */
export enum ButtonType {
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  default = 'default',
  primary = 'primary',
  primaryInverted = 'primary-inverted',
  gray = 'gray',
  stopRecord = 'stop-record'
}

/**
 * ButtonSize represents different button sizes (small, medium, large)
 */
export enum ButtonSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  full = 'full'
}

/**
 * ButtonProps interface
 * - tracking: object (button event name for tracking)
 * - children: React.ReactNode (children that renders inside button)
 * - type: ButtonType (define button type, "default" is the default one)
 * - size: ButtonSize (define button size, "medium" is the default one)
 * - disabled: boolean (is button disabled or not)
 * - onClick: Function (callback function that triggers when click on button)
 */
export interface ButtonProps {
  tracking?: {
    action: string;
    category: string;
    label: string;
  };
  children: React.ReactNode;
  type?: ButtonType;
  size?: ButtonSize;
  disabled?: boolean;
  loading?: boolean;
  rounded?: boolean;
  buttonName?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  pulseEffect?: boolean;
}

/**
 * Button component renders a button with different colors and sizes
 * based on button type/size/disabled status, trigger event tracking with action, category and label
 * is provided and onClick callback function
 * @param tracking: objecy (button event name for tracking)
 * @param children: React.ReactNode (children that renders inside button)
 * @param type: ButtonType (define button type, "default" is the default one)
 * @param size: ButtonSize (define button size, "medium" is the default one)
 * @param disabled: boolean (is button disabled or not)
 * @param onClick: Function (callback function that triggers when click on button)
 */
const Button: React.FC<ButtonProps> = ({
  tracking,
  children,
  type = ButtonType.default,
  size = ButtonSize.medium,
  disabled,
  loading = false,
  rounded = false,
  className,
  buttonName,
  onClick,
  onMouseDown,
  onMouseUp,
  pulseEffect
}) => {
  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!tracking) {
      console.warn('No tracking value is provided!');
    } else {
      trackEvent(tracking.action, { category: tracking.category, label: tracking.label });
    }
    if (!disabled && onClick) {
      onClick(event);
    }
  }
  function handleMouseDown(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!tracking) {
      console.warn('No tracking value is provided!');
    } else {
      trackEvent(tracking.action, { category: tracking.category, label: tracking.label });
    }
    if (!disabled && onMouseDown) {
      onMouseDown(event);
    }
  }
  function handleMouseUp(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!tracking) {
      console.warn('No tracking value is provided!');
    } else {
      trackEvent(tracking.action, { category: tracking.category, label: tracking.label });
    }
    if (!disabled && onMouseUp) {
      onMouseUp(event);
    }
  }

  return (
    <div className={`${styles['button-container']} ${styles[size]} ${className}  `}>
      <button
        className={`${styles['button']} ${styles[type]}  ${styles[size]}  ${
          rounded ? styles['rounded'] : ''
        } ${pulseEffect ? styles['pulseEffect'] : ''}`}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        disabled={disabled}
      >
        {/* {loading && (
          <div className={styles['button-container__loader']}>
            <CircularProgress
              className={styles['button-container__circle']}
              size={24}
              color="inherit"
            />
          </div>
        )} */}
        {children}
      </button>
      {buttonName && <span>{buttonName}</span>}
    </div>
  );
};

export default Button;
