import { BranchDataModel } from '../../models/merchant.model';
import { BranchDataUIModel } from '../../ui-models/merchants.ui-model';

function mapToUI(branch: BranchDataModel): BranchDataUIModel {
  return {
    id: branch.id,
    companyId: branch.companyId,
    branchCity: branch.branchCity,
    branchCityId: {
      id: branch.branchCityId ?? '',
      label: `${branch.branchCity?.en} - ${branch.branchCity?.ar}`
    },
    branchArea: branch.branchArea,
    branchAreaId: {
      id: branch.branchAreaId ?? '',
      label: `${branch.branchArea?.en} - ${branch.branchArea?.ar}`
    },
    branchManagerNameEn: branch.branchManagerName?.en,
    branchManagerNameAr: branch.branchManagerName?.ar,
    branchManagerPhoneNumber: branch.branchManagerPhoneNumber,
    branchManagerEmail: branch.branchManagerEmail,
    isFirstBranch: branch.isFirstBranch
  };
}

function mapToModel(branch: BranchDataUIModel, initialBranch?: BranchDataUIModel): BranchDataModel {
  return {
    ...(branch.id && { id: branch.id }),
    ...(branch.companyId && { companyId: branch.companyId }),
    ...(branch.branchCityId?.id && { branchCityId: branch.branchCityId.id }),
    ...(branch.branchAreaId?.id && { branchAreaId: branch.branchAreaId.id }),
    ...(branch.branchManagerNameEn && {
      branchManagerName: {
        en: branch.branchManagerNameEn,
        ar: (initialBranch ? initialBranch.branchManagerNameAr : branch.branchManagerNameAr) ?? ''
      }
    }),
    ...(branch.branchManagerNameAr && {
      branchManagerName: {
        en: (initialBranch ? initialBranch.branchManagerNameEn : branch.branchManagerNameEn) ?? '',
        ar: branch.branchManagerNameAr
      }
    }),
    ...(branch.branchManagerPhoneNumber && {
      branchManagerPhoneNumber: branch.branchManagerPhoneNumber
    }),
    ...(branch.branchManagerEmail && { branchManagerEmail: branch.branchManagerEmail }),
    ...(branch.isFirstBranch !== undefined && { isFirstBranch: branch.isFirstBranch })
  };
}

const branchesMapper = { mapToUI, mapToModel };

export default branchesMapper;
