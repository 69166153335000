import { ResponseModel } from '../../interfaces/response-model.interface';
import { CompanyContactPersonModel } from '../../models/merchant.model';
import { CompanyContactPersonUIModel } from '../../ui-models/merchants.ui-model';

function mapToModel(
  merchantId: string,
  contactPerson: CompanyContactPersonUIModel,
  initialValues?: CompanyContactPersonUIModel
): CompanyContactPersonModel {
  return {
    ...(merchantId && { id: merchantId }),
    ...(contactPerson.jobTitleEn && {
      contactPersonJobTitle: {
        en: contactPerson.jobTitleEn,
        ar: (initialValues ? initialValues.jobTitleAr : contactPerson.jobTitleAr) ?? ''
      }
    }),
    ...(contactPerson.jobTitleAr && {
      contactPersonJobTitle: {
        en: (initialValues ? initialValues.jobTitleEn : contactPerson.jobTitleEn) ?? '',
        ar: contactPerson.jobTitleAr
      }
    }),
    ...(contactPerson.nameEn && {
      contactPersonName: {
        en: contactPerson.nameEn,
        ar: (initialValues ? initialValues.nameAr : contactPerson.nameAr) ?? ''
      }
    }),
    ...(contactPerson.nameAr && {
      contactPersonName: {
        en: (initialValues ? initialValues.nameEn : contactPerson.nameEn) ?? '',
        ar: contactPerson.nameAr
      }
    }),
    ...(contactPerson.email && { contactPersonEmail: contactPerson.email }),
    ...(contactPerson.phoneNumber && {
      contactPersonPhoneNumber: contactPerson.phoneNumber
    })
  };
}

function mapToUI(merchant: ResponseModel<CompanyContactPersonModel>): CompanyContactPersonUIModel {
  return {
    id: merchant.data.id,
    jobTitleEn: merchant.data.contactPersonJobTitle?.en ?? '',
    jobTitleAr: merchant.data.contactPersonJobTitle?.ar ?? '',
    nameEn: merchant.data.contactPersonName?.en ?? '',
    nameAr: merchant.data.contactPersonName?.ar ?? '',
    email: merchant.data.contactPersonEmail ?? '',
    phoneNumber: merchant.data.contactPersonPhoneNumber ?? ''
  };
}

const contactPersonMapper = { mapToModel, mapToUI };

export default contactPersonMapper;
