import moment, { isMoment, Moment, ISO_8601 } from 'moment-timezone';

export const isMobileView = () => {
  return window.innerWidth <= 768;
};

export const isTabletView = () => {
  return window.innerWidth <= 992;
};

const ISO_8601_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

export const isValidDate = (str: string | Moment) => {
  if (isMoment(str)) {
    return true;
  } else if (typeof str === 'string' && ISO_8601_REGEX.test(str)) {
    return moment(str, ISO_8601, true).isValid();
  }
  return false;
};
