import { create } from 'zustand';
import moment from 'moment-timezone';
import { GridSortDirection } from '@mui/x-data-grid';
import { isErrorInfo } from '../../../../shared/interfaces/error-info.interface';
import { searchFinanceApplicationReport } from '../../../../shared/services/data/finance-applications-report/finance-applications-report.data';
import { getModuleName } from '../services/finance-applications-reports.service';
import { getTableSorting } from '../../../../shared/services/helpers/get-table-sorting/get-table-sorting';
import { State } from './Finance-applications-reports.store.types';
import { searchCompanies } from '../../../../shared/services/data/merchants.data';
import productMerchantMapper from '../../../../shared/mappers/products-mappers/auto-products-mapper/product-merchant.mapper';

const useFinanceApplicationReportStore = create<State>((set, state) => ({
  data: [],
  totalCount: 0,
  totalFinanceAmount: 0,
  loading: false,
  tableLoading: false,
  currentPage: 1,
  rowsPerPage: 10,
  sortModel: [{ field: 'lastUpdatedAt', sort: 'desc' as GridSortDirection }],
  query: {
    skip: 0,
    limit: 10,
    sort: getTableSorting([{ field: 'lastUpdatedAt', sort: 'desc' }])[0]
  },
  error: null,
  financeMerchantSearchQuery: '',
  financeMerchantSelectOptions: [],
  carofiAgentsSelectOptions: [],
  searchLoading: false,
  dataLoading: false,
  actions: {
    getFinanceApplicationReports: async (payload) => {
      set({ loading: true, error: null, tableLoading: true });
      try {
        const res = await searchFinanceApplicationReport(payload, getModuleName());
        if (isErrorInfo(res)) {
          set({ error: res.errorMessage });
        } else {
          set({
            data: res.data,
            totalCount: res.total_count,
            totalFinanceAmount: res.totalFinanceAmount
          });
        }
      } finally {
        set({ loading: false, tableLoading: false });
      }
    },
    setCurrentPage: (page) => set({ currentPage: page }),
    setRowsPerPage: (rows) => set({ rowsPerPage: rows }),
    setSortModel: (model) => set({ sortModel: model }),
    setFinanceMerchantSearchQuery: (financeMerchantSearchQuery: string) =>
      set({ financeMerchantSearchQuery }),
    setSearchLoading: (searchLoading: boolean) => set({ searchLoading }),

    setQuery: (values, skip?) => {
      const { currentPage, rowsPerPage, sortModel } = state();
      const { startDate, endDate, agent, financialMerchantId, status, source } = values;

      const payloadQuery = {
        ...(startDate && { startDate: moment(startDate).format('YYYY-MM-DD') }),
        ...(endDate && { endDate: moment(endDate).format('YYYY-MM-DD') }),
        ...(status?.length && { status: status.map((s) => s.id) }),
        ...(agent?.length && { agentId: agent.map((a) => a.id) }),
        ...(financialMerchantId?.id && {
          financialMerchantId: [financialMerchantId.id]
        }),
        ...(source?.length && { customerSource: source.map((s) => s.id) })
      };

      set({
        query: {
          skip: skip === 0 ? 0 : (currentPage - 1) * Number(rowsPerPage),
          limit: Number(rowsPerPage),
          sort: getTableSorting(sortModel)[0],
          ...(Object.keys(payloadQuery).length && { query: payloadQuery })
        },
        currentPage: skip === 0 ? 1 : currentPage,
        rowsPerPage: Number(rowsPerPage)
      });
    },
    getFinanceMerchants: async ({ merchantType, showAlert }) => {
      const tablePayload = {
        skip: 0,
        limit: 50,
        query: {},
        sort: {}
      };
      const searchQuery = state().financeMerchantSearchQuery;

      try {
        const response = await searchCompanies(
          {
            ...tablePayload,
            query: {
              ...(searchQuery && { companyName: searchQuery }),
              ...(merchantType && { merchantType: [merchantType] })
            }
          },
          getModuleName()
        );

        if (isErrorInfo(response)) {
          showAlert(response.errorMessage);
        } else {
          set({
            financeMerchantSelectOptions: productMerchantMapper.mapToUi(response.data),
            searchLoading: false
          });
        }
      } catch (error) {
        showAlert('Failed to fetch finance merchants');
      }
    },
    setCarofiAgentsSelectOptions: (options) => set({ carofiAgentsSelectOptions: options })
  }
}));

export default useFinanceApplicationReportStore;
