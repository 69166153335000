import { memo } from 'react';

const SelectImage = memo(function Image({ src }: { src: string }) {
  return (
    <img
      loading="lazy"
      alt=""
      style={{ height: '3.2rem', width: '3.2rem', marginRight: '1.6rem' }}
      src={src}
    />
  );
});

export default SelectImage;
