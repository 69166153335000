import _ from 'lodash';
import { ProductImagesProps } from '../../../models/auto-product.model';
import { ProductDocumentsUiModel } from '../../../ui-models/auto-products.ui-model';

function mapToUi(productImages: ProductImagesProps[]): ProductDocumentsUiModel {
  const groupedFiles = _.groupBy(productImages, (img) => img.imageType);
  return groupedFiles;
}

const productDocumentsMapper = { mapToUi };

export default productDocumentsMapper;
