import { ThemeOptions } from '@mui/material';
import { merge } from 'lodash';
import AppBar from './appBar';
import Avatar from './avatar';
import Button from './button';
import IconButton from './iconButton';
import ListItemButton from './listItemButton';
import TextField from './textField';
import ToolBar from './toolBar';
import RangeDatePicker from './rangePicker';

export default function ComponentsOverrides(theme: ThemeOptions) {
  return merge(
    Button(theme),
    AppBar(theme),
    IconButton(theme),
    ToolBar(theme),
    ListItemButton(theme),
    Avatar(theme),
    TextField(theme),
    RangeDatePicker(theme)
  );
}
