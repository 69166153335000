import { FinanceApplicationsReportModel } from '../../models/finance-application-report.model';
import { FinanceApplicationsReportUiModel } from '../../ui-models/finance-application-report.ui-model';
import companyDetailsMapper from '../merchant-mappers/company-details.mapper';
import productDetailsMapper from '../products-mappers/auto-products-mapper/product-details.mapper';

function mapSingleReportToUi(
  report: FinanceApplicationsReportModel
): FinanceApplicationsReportUiModel {
  return {
    id: report.id,
    shortId: report.shortId ?? '',
    bookingEffectiveDate: report.bookingEffectiveDate ?? '--',
    financedAmount: report.financedAmount ?? 0,
    downPayment: report.downPayment ?? 0,
    tenor: report.tenor ?? 0,
    status: report.status ?? '--',
    productType: report.product?.productType ?? '',
    carModel: {
      carBrandId: report.product?.carBrandId ?? '',
      name: report.product?.carModel ?? {
        ar: '',
        en: ''
      },
      automotiveTypeId: report.product?.automotiveTypeId ?? '',
      bodyTypeId: report.product?.bodyTypeId ?? ''
    },
    carBrand: {
      countryOriginId: report.product?.carBrand?.countryOriginId ?? '',
      name: report.product?.carBrand?.name ?? {
        ar: '',
        en: ''
      }
    },
    customerSource: report.customerSource,
    countryOriginName: report.product?.carBrand?.countryOriginName?.en ?? '',
    userName: report?.user?.name ?? '',
    customerName: report?.customer?.personalData?.idNameArabic || '',
    customerShortId: report?.customer.personalData.shortId || 0,
    agentId: report.agentId ?? '',
    agent: report?.agent,
    merchantAgentId: report.merchantAgentId ?? '',
    merchantAgent: report?.merchantAgent,
    financialMerchant: companyDetailsMapper.mapToUI(report.financialMerchant),
    autoProduct: report.product ? productDetailsMapper.mapToUi(report.product) : undefined,
    idNumber: report?.customer?.personalData?.idNumber ?? ''
  };
}

function mapToUi(
  financeApplicationsReport: FinanceApplicationsReportModel[]
): FinanceApplicationsReportUiModel[] {
  return financeApplicationsReport.map(mapSingleReportToUi);
}

const financeApplicationsReportMapper = { mapToUi };

export default financeApplicationsReportMapper;
