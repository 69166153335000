import { ThemeOptions } from '@mui/material';

export default function ListItemButton(theme: ThemeOptions) {
  return {
    MuiListItemButton: {
      styleOverrides: {
        root: {}
      }
    }
  };
}
