export enum MenuItems {
  DASHBOARD_MODULE = 'DASHBOARD_MODULE',
  CUSTOMERS = 'CUSTOMERS',
  PARTNERS = 'PARTNERS',
  LEADS = 'LEADS',
  OPERATIONS = 'OPERATIONS',
  // PAYMENTS_MODULE = 'PAYMENTS_MODULE',
  PRODUCTS = 'PRODUCTS',
  REPORTS = 'REPORTS',
  USERS = 'USERS',
  SALES = 'SALES',
  COMMUNICATION = 'COMMUNICATION',
  SETTINGS = 'SETTINGS'
}
