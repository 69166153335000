import { FinanceApplicationReportUIFilters } from '../../../../../shared/ui-models/finance-application-report.ui-model';

export const initialFinanceApplicationsFiltersValues: FinanceApplicationReportUIFilters = {
  startDate: null,
  endDate: null,
  status: [],
  agent: [],
  financialMerchantId: { id: '', label: '' },
  source: []
};

export const validateFinanceApplicationReportFiltersForm = (
  fieldValues: FinanceApplicationReportUIFilters,
  errors: { [key: string]: string },
  setErrors: (error: any) => void
) => {
  const temp: { [key: string]: unknown } = { ...errors };

  if ('startDate' in fieldValues)
    temp.startDate = fieldValues.startDate ? '' : 'This field is required';
  if ('endDate' in fieldValues) temp.endDate = fieldValues.endDate ? '' : 'This field is required';

  // endDate should be greater than startDate
  if (fieldValues.startDate && fieldValues.endDate) {
    if (fieldValues.startDate > fieldValues.endDate) {
      temp.endDate = 'End date should be greater than start date';
    }
  }

  setErrors({
    ...temp
  });

  const isValidForm = Object.values(temp).every((x) => x === '');
  if (isValidForm) {
    return { valid: true, error: '' };
  } else {
    return {
      valid: false,
      error: 'Make sure to fill all mandatory data'
    };
  }
};
