import { FC } from 'react';
import type { Moment } from 'moment';

import { useTheme } from '@mui/material/styles';
import { DateView } from '@mui/x-date-pickers/models';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';

import { ReactComponent as DatePickerIcon } from '../../../../../assets/svgs/calendar.icon.svg';

interface DatePickerProps {
  data: {
    name: string;
    value: Moment;
    error?: string;
    helperText?: string;
  };
  config: {
    views?: DateView[];
    format?: string;
    disableFuture?: boolean;
    isDisabled?: boolean;
    shouldDisableYear?: (year: string | Moment) => boolean;
  };
  eventHandlers: {
    handleInputChange: (e: any) => void;
  };
}

const DatePicker: FC<DatePickerProps> = ({ data, config, eventHandlers }) => {
  const { value, error, helperText = ' ', name } = data;
  const { views, format, disableFuture = false, isDisabled = false, shouldDisableYear } = config;
  const { handleInputChange } = eventHandlers;

  const theme = useTheme();

  const convertToDefEventPara = (name: string, value: Moment | string | null) => ({
    target: {
      name,
      // setting default time for the moment object to avoid time zone issues when saving the DATE in the database
      value: value ? (value as Moment).clone().set({ hour: 10, minute: 0, second: 0 }) : value
    }
  });

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={['DatePicker']}>
        <MuiDatePicker
          label=""
          value={error ? null : value}
          format={format}
          views={views}
          disabled={isDisabled}
          disableFuture={disableFuture}
          shouldDisableYear={shouldDisableYear}
          onChange={(e) => handleInputChange(convertToDefEventPara(name, e))}
          slots={{
            openPickerIcon: () => <DatePickerIcon />
          }}
          slotProps={{
            textField: {
              helperText: error ? error : helperText
            },
            switchViewButton: { color: 'primary' },
            leftArrowIcon: { color: 'primary' },
            rightArrowIcon: { color: 'primary' }
          }}
          sx={{
            '& .MuiFormHelperText-root': {
              color: error ? theme.palette.error.main : theme.palette.grey[400]
            },
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline ': {
              border: `1px solid ${error ? theme.palette.error.main : theme.palette.grey[200]}`
            }
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default DatePicker;
