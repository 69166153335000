import { ThemeOptions } from '@mui/material/styles';

// ==============================|| DEFAULT THEME - CUSTOM SHADOWS  ||============================== //

const CustomShadows = (theme: ThemeOptions) => ({
  appBar: `drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.05))`,
  sideMenu: `drop-shadow(2px 0px 4px rgba(0, 0, 0, 0.05))`,
  subHeader: `0px 2px 4px rgba(0, 51, 102, 0.05)`
});

export default CustomShadows;

declare module '@mui/material/styles' {
  interface Theme {
    customShadows: {
      appBar: string;
      sideMenu: string;
      subHeader: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    customShadows?: {
      appBar: string;
      sideMenu: string;
      subHeader: string;
    };
  }
}
