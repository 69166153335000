import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import type { Middleware } from 'redux';
import middleware from '../middleware';
import rootReducer from '../reducers/index';

const ReduxStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(...(middleware as Middleware[]))
});

export default ReduxStore;
