import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '../../../../shared/config/routes-config';
import { UserRole } from '../../../../shared/enums/user-role.enum';
import { RouteConfig } from '../../../../shared/interfaces/routes-config.interface';
import DefaultLayout from '../../../../shared/layouts/default/default.layout';

const TeamsListContainer = React.lazy(
  () => import('../../containers/teams/teams-list/teams-list.container')
);

const TeamsAddContainer = React.lazy(
  () => import('../../containers/teams/teams-add/teams-add.container')
);

const TeamsEditContainer = React.lazy(
  () => import('../../containers/teams/teams-edit/teams-edit.container')
);

const MemberDetailsContainer = React.lazy(
  () => import('../../containers/teams/member-details/member-details.container')
);

const KPIsContainer = React.lazy(() => import('../../containers/KPIs/KPIs.container'));

const CommissionSchemeContainer = React.lazy(
  () => import('../../containers/commission-scheme/commission-scheme.container')
);

const ArchiveListContainer = React.lazy(
  () => import('../../containers/archive/archive-list/archive-list.container')
);

const ArchiveTeamDetailsContainer = React.lazy(
  () => import('../../containers/archive/archive-team-details/archive-team-details.container')
);

export const SalesManagementRoutesConfig: RouteObject[] = [
  {
    index: true,
    element: (
      <Navigate
        to={(APP_ROUTES.SALES_MANAGEMENT_MODULE.CHILDREN as RouteConfig).TEAMS_LIST.FULL_PATH}
      />
    )
  },
  {
    path: (APP_ROUTES.SALES_MANAGEMENT_MODULE.CHILDREN as RouteConfig).TEAMS_LIST.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[UserRole.ADMIN]}
          component={TeamsListContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.SALES_MANAGEMENT_MODULE.CHILDREN as RouteConfig).TEAMS_ADD.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[UserRole.ADMIN]}
          component={TeamsAddContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.SALES_MANAGEMENT_MODULE.CHILDREN as RouteConfig).TEAMS_EDIT.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[UserRole.ADMIN]}
          component={TeamsEditContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.SALES_MANAGEMENT_MODULE.CHILDREN as RouteConfig).TEAM_MEMBER_DETAILS
      .FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[UserRole.ADMIN]}
          component={MemberDetailsContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.SALES_MANAGEMENT_MODULE.CHILDREN as RouteConfig).KPIS.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[UserRole.ADMIN]}
          component={KPIsContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.SALES_MANAGEMENT_MODULE.CHILDREN as RouteConfig).COMMISSION_SCHEME.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[UserRole.ADMIN]}
          component={CommissionSchemeContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.SALES_MANAGEMENT_MODULE.CHILDREN as RouteConfig).ARCHIVE.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[UserRole.ADMIN]}
          component={ArchiveListContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.ARCHIVE_MODULE.CHILDREN as RouteConfig).DETAILS.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[UserRole.ADMIN]}
          component={ArchiveTeamDetailsContainer}
        />
      </React.Suspense>
    )
  }
];
