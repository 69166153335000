import { MerhcantSelectOptions } from '../../../../Modules/ProductsModule/AutoProductsModule/shared/store/used-product.store';
import { CompanyDetailsUIModel } from '../../../ui-models/merchants.ui-model';

function mapToUi(merchants: CompanyDetailsUIModel[]): MerhcantSelectOptions[] {
  const merchantsList = merchants.map((merchant) => {
    return {
      id: merchant.id ?? '',
      label: merchant.companyNameEn ?? '',
      imageUrl: merchant.companyLogo?.url ?? '',
      legalName: merchant.companyLegalNameEn ?? ''
    };
  });
  return merchantsList;
}

const productMerchantMapper = { mapToUi };

export default productMerchantMapper;
