// @ts-nocheck
import { ThemeOptions } from '@mui/material';

export default function Avatar(theme: ThemeOptions) {
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: theme.spacing(4.5),
          height: theme.spacing(4.5),
          borderRadius: '9px',
          marginRight: theme.spacing(1.5),
          backgroundColor: theme.palette?.grey[300]
        }
      }
    }
  };
}
