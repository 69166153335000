import { SxProps } from '@mui/material';
import { StyledTitle } from './form-section.title.styles';

interface FormSectionTitleProps {
  title: string;
  style?: SxProps;
}
function FormSectionTitle(props: FormSectionTitleProps) {
  const { title, style } = props;

  return <StyledTitle sx={style}>{title}</StyledTitle>;
}
export default FormSectionTitle;
