import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '../../../../shared/config/routes-config';
import { UserRole } from '../../../../shared/enums/user-role.enum';
import { RouteConfig } from '../../../../shared/interfaces/routes-config.interface';
import DefaultLayout from '../../../../shared/layouts/default/default.layout';

const CorporateWhatsappContainer = React.lazy(
  () => import('../../containers/corporate-whatsapp/corporate-whatsapp.container')
);
const ConsumerWhatsappContainer = React.lazy(
  () => import('../../containers/consumer-whatsapp/consumer-whatsapp.container')
);

export const CommunicationRoutesConfig: RouteObject[] = [
  {
    index: true,
    element: (
      <Navigate
        to={(APP_ROUTES.COMMUNICATION_MODULE.CHILDREN as RouteConfig).CONSUMER_WHATSAPP.FULL_PATH}
      />
    )
  },
  {
    path: (APP_ROUTES.COMMUNICATION_MODULE.CHILDREN as RouteConfig).CONSUMER_WHATSAPP.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.ADMIN,
            UserRole.DIGITAL_SALES_MANAGER,
            UserRole.DIGITAL_SALES_LEAD,
            UserRole.CORPORATE_SALES_SENIOR_AGENT,
            UserRole.DIGITAL_SALES_AGENT
          ]}
          component={ConsumerWhatsappContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.COMMUNICATION_MODULE.CHILDREN as RouteConfig).CORPORATE_WHATSAPP.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.ADMIN,
            UserRole.DIGITAL_SALES_MANAGER,
            UserRole.DIGITAL_SALES_LEAD,
            UserRole.CORPORATE_SALES_SENIOR_AGENT,
            UserRole.DIGITAL_SALES_AGENT
          ]}
          component={CorporateWhatsappContainer}
        />
      </React.Suspense>
    )
  }
];
