import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../interfaces/error-info.interface';
import { ResponseModel } from '../../../interfaces/response-model.interface';

import errorHelper from '../../helpers/error-helper/error-helper.service';
import { financeApplicationsApi } from '../../apis/finance-applications-apis/finance-applications-api.service';
import financeApplicationsOverviewReportMapper from '../../../mappers/finance-applications/finance-applications-overview-report.mapper';
import { FinanceApplicationsOverviewReportModel } from '../../../models/finance-applications-overview-report.model';
import { FinanceApplicationsOverviewReportUiModel } from '../../../ui-models/finance-applications-overview-report.ui-model';
import { FinanceApplicationsFrequencyReportModel } from '../../../models/finance-applications-frequency-report.model';
import { FinanceApplicationsFrequencyReportUiModel } from '../../../ui-models/finance-applications-frequency-report.ui-model';
import financeApplicationsMonthlyReportMapper from '../../../mappers/leads/finance-applications-monthly-report.mapper';
import { UserModel } from '../../../models/user.model';
import financeApplicationsAgentReportMapper from '../../../mappers/finance-applications/finance-applications-agent-report.mapper';
import { FinanceApplicationsAgentReportModel } from '../../../models/finance-applications-agent-report.model';
import { FinanceApplicationsAgentReportUiModel } from '../../../ui-models/finance-applications-agent-report.ui-model';
import {
  ApplicationReportStatusEnum,
  FinAppReportStatus,
  FinAppStatus
} from '../../../../Modules/CustomersModule/shared/enums/customer-finance-application.enum';
import { FinanceApplicationsMerchantReportModel } from '../../../models/finance-applications-merchant-report.model';
import { FinanceApplicationsMerchantReportUiModel } from '../../../ui-models/finance-applications-merchant-report.ui-model';
import financeApplicationsMerchantReportMapper from '../../../mappers/finance-applications/finance-applications-merchant-report.mapper';

export async function getApplicationsOverviewReport(
  startDate: string,
  endDate: string,
  financialMerchantId: string[] | undefined,
  assignedTo: string[] | undefined,
  containerName: string
): Promise<FinanceApplicationsOverviewReportUiModel | ErrorInfo> {
  return financeApplicationsApi
    .overviewReport(startDate, endDate, financialMerchantId, assignedTo)
    .then(
      (response: ApiResponse<ResponseModel<FinanceApplicationsOverviewReportModel>, ErrorInfo>) => {
        if (response.ok) {
          const { data } = response;
          if (data && data.data) {
            return financeApplicationsOverviewReportMapper.mapToUi(data.data);
          } else {
            const error: ErrorInfo = errorHelper(
              0,
              '',
              containerName,
              (financeApplicationsApi.overviewReport as any).name
            );
            return error;
          }
        } else {
          const error: ErrorInfo = errorHelper(
            Number(response.status),
            response.data && response.data.errorCode
              ? String(response.data.errorCode || '')
              : response.problem,
            containerName,
            (financeApplicationsApi.overviewReport as any).name
          );
          return error;
        }
      }
    );
}

export async function getApplicationsMonthlyReport(
  metric: 'amount' | 'count',
  startDate: string,
  endDate: string,
  financialMerchantId: string[] | undefined,
  assignedTo: string[] | undefined,
  containerName: string
): Promise<FinanceApplicationsFrequencyReportUiModel | ErrorInfo> {
  return financeApplicationsApi
    .frequencyReport('monthly', metric, startDate, endDate, financialMerchantId, assignedTo)
    .then(
      (
        response: ApiResponse<ResponseModel<FinanceApplicationsFrequencyReportModel>, ErrorInfo>
      ) => {
        if (response.ok) {
          const { data } = response;
          if (data && data.data) {
            return financeApplicationsMonthlyReportMapper.mapToUi(data.data);
          } else {
            const error: ErrorInfo = errorHelper(
              0,
              '',
              containerName,
              (financeApplicationsApi.frequencyReport as any).name
            );
            return error;
          }
        } else {
          const error: ErrorInfo = errorHelper(
            Number(response.status),
            response.data && response.data.errorCode
              ? String(response.data.errorCode || '')
              : response.problem,
            containerName,
            (financeApplicationsApi.frequencyReport as any).name
          );
          return error;
        }
      }
    );
}

export async function getFinanceApplicationsPerformanceReport(
  startDate: string,
  endDate: string,
  applicationStatus: ApplicationReportStatusEnum,
  financialMerchantId: string[] | undefined,
  assignedTo: string[] | undefined,
  containerName: string
): Promise<FinanceApplicationsAgentReportUiModel | ErrorInfo> {
  return financeApplicationsApi
    .performanceReport(startDate, endDate, applicationStatus, financialMerchantId, assignedTo)
    .then(
      (
        response: ApiResponse<
          ResponseModel<{ [key: string]: FinanceApplicationsAgentReportModel }>,
          ErrorInfo
        >
      ) => {
        if (response.ok) {
          const { data } = response;
          if (data && data.data) {
            return financeApplicationsAgentReportMapper.mapToUi(data.data);
          } else {
            const error: ErrorInfo = errorHelper(
              0,
              '',
              containerName,
              (financeApplicationsApi.performanceReport as any).name
            );
            return error;
          }
        } else {
          const error: ErrorInfo = errorHelper(
            Number(response.status),
            response.data && response.data.errorCode
              ? String(response.data.errorCode || '')
              : response.problem,
            containerName,
            (financeApplicationsApi.performanceReport as any).name
          );
          return error;
        }
      }
    );
}

export async function getFinanceApplicationsMerchantsReport(
  startDate: string,
  endDate: string,
  status: ApplicationReportStatusEnum,
  containerName: string
): Promise<FinanceApplicationsMerchantReportUiModel[] | ErrorInfo> {
  return financeApplicationsApi
    .merchantReport(startDate, endDate, status)
    .then(
      (
        response: ApiResponse<ResponseModel<FinanceApplicationsMerchantReportModel[]>, ErrorInfo>
      ) => {
        if (response.ok) {
          const { data } = response;
          if (data && data.data) {
            return financeApplicationsMerchantReportMapper.mapToUiList(data.data);
          } else {
            const error: ErrorInfo = errorHelper(
              0,
              '',
              containerName,
              (financeApplicationsApi.merchantReport as any).name
            );
            return error;
          }
        } else {
          const error: ErrorInfo = errorHelper(
            Number(response.status),
            response.data && response.data.errorCode
              ? String(response.data.errorCode || '')
              : response.problem,
            containerName,
            (financeApplicationsApi.merchantReport as any).name
          );
          return error;
        }
      }
    );
}

export async function getAllAgents(containerName: string): Promise<UserModel[] | ErrorInfo> {
  return financeApplicationsApi
    .getAllAgents()
    .then((response: ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data.data ?? [];
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (financeApplicationsApi.getAllAgents as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (financeApplicationsApi.getAllAgents as any).name
        );
        return error;
      }
    });
}

// export async function getLeadsDailyReport(
//   startDate: string,
//   endDate: string,
//   assignedTo: string | undefined,
//   containerName: string
// ): Promise<LeadsDailyReportUiModel | ErrorInfo> {
//   return leadsApi
//     .dailyReport(startDate, endDate, assignedTo)
//     .then((response: ApiResponse<ResponseModel<LeadsDailyReportModel>, ErrorInfo>) => {
//       if (response.ok) {
//         const { data } = response;
//         if (data && data.data) {
//           return leadsDailyReportMapper.mapToUi(data.data);
//         } else {
//           const error: ErrorInfo = errorHelper(
//             0,
//             '',
//             containerName,
//             (leadsApi.dailyReport as any).name
//           );
//           return error;
//         }
//       } else {
//         const error: ErrorInfo = errorHelper(
//           Number(response.status),
//           response.data && response.data.errorCode
//             ? String(response.data.errorCode || '')
//             : response.problem,
//           containerName,
//           (leadsApi.dailyReport as any).name
//         );
//         return error;
//       }
//     });
// }

// export async function getLeadsChannelReport(
//   startDate: string,
//   endDate: string,
//   assignedTo: string | undefined,
//   containerName: string
// ): Promise<LeadsChannelReportUiModel[] | ErrorInfo> {
//   return leadsApi
//     .channelReport(startDate, endDate, assignedTo)
//     .then((response: ApiResponse<ResponseModel<LeadsChannelReportModel[]>, ErrorInfo>) => {
//       if (response.ok) {
//         const { data } = response;
//         if (data && data.data) {
//           return leadsChannelReportMapper.mapToUiList(data.data);
//         } else {
//           const error: ErrorInfo = errorHelper(
//             0,
//             '',
//             containerName,
//             (leadsApi.channelReport as any).name
//           );
//           return error;
//         }
//       } else {
//         const error: ErrorInfo = errorHelper(
//           Number(response.status),
//           response.data && response.data.errorCode
//             ? String(response.data.errorCode || '')
//             : response.problem,
//           containerName,
//           (leadsApi.channelReport as any).name
//         );
//         return error;
//       }
//     });
// }

// export async function getLeadsPerformanceReport(
//   startDate: string,
//   endDate: string,
//   status: string[] | undefined,
//   resolutionType: string[] | undefined,
//   containerName: string
// ): Promise<LeadsPerformanceReportUiModel[] | ErrorInfo> {
//   return leadsApi
//     .performanceReport(startDate, endDate, status, resolutionType)
//     .then((response: ApiResponse<ResponseModel<LeadsPerformanceReportModel[]>, ErrorInfo>) => {
//       if (response.ok) {
//         const { data } = response;
//         if (data && data.data) {
//           return leadsPerformanceReportMapper.mapToUiList(data.data);
//         } else {
//           const error: ErrorInfo = errorHelper(
//             0,
//             '',
//             containerName,
//             (leadsApi.performanceReport as any).name
//           );
//           return error;
//         }
//       } else {
//         const error: ErrorInfo = errorHelper(
//           Number(response.status),
//           response.data && response.data.errorCode
//             ? String(response.data.errorCode || '')
//             : response.problem,
//           containerName,
//           (leadsApi.performanceReport as any).name
//         );
//         return error;
//       }
//     });
// }
