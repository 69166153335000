import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '../../../../shared/config/routes-config';
import { UserRole } from '../../../../shared/enums/user-role.enum';
import { RouteConfig } from '../../../../shared/interfaces/routes-config.interface';
import DefaultLayout from '../../../../shared/layouts/default/default.layout';

const MerchantsListContainer = React.lazy(
  () => import('../../containers/merchants-list/merchants-list.container')
);

const MerchantAutoDetailsContainer = React.lazy(
  () => import('../../containers/merchant-auto-details/merchant-auto-details.container')
);

const MerchantAddContainer = React.lazy(
  () => import('../../containers/merchant-add/merchant-add.container')
);

const MerchantEditContainer = React.lazy(
  () => import('../../containers/merchant-edit/merchant-edit.container')
);

export const MerchantRoutesConfig: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={(APP_ROUTES.MERCHANT_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH} />
  },
  {
    path: (APP_ROUTES.MERCHANT_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.E_COMMERCE_FINANCE,
            UserRole.E_COMMERCE_AUTO,
            UserRole.E_COMMERCE_MANAGER,
            UserRole.ADMIN
          ]}
          component={MerchantsListContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.MERCHANT_MODULE.CHILDREN as RouteConfig).AUTO_DETAILS.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.E_COMMERCE_FINANCE,
            UserRole.E_COMMERCE_AUTO,
            UserRole.E_COMMERCE_MANAGER,
            UserRole.ADMIN
          ]}
          component={MerchantAutoDetailsContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.MERCHANT_MODULE.CHILDREN as RouteConfig).ADD.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          addLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.E_COMMERCE_FINANCE,
            UserRole.E_COMMERCE_AUTO,
            UserRole.E_COMMERCE_MANAGER,
            UserRole.ADMIN
          ]}
          component={MerchantAddContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.MERCHANT_MODULE.CHILDREN as RouteConfig).EDIT.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          addLayout
          authenticationRequired={true}
          permittedList={[
            UserRole.E_COMMERCE_FINANCE,
            UserRole.E_COMMERCE_AUTO,
            UserRole.E_COMMERCE_MANAGER,
            UserRole.ADMIN
          ]}
          component={MerchantEditContainer}
        />
      </React.Suspense>
    )
  }
];
