import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const StyledDatePickerContainer = styled(Box)<BoxProps>(({ theme }) => ({
  '& .MuiFormHelperText-root': {
    margin: '4px 0px 0px',
    fontSize: '1.4rem',
    fontWeight: 400,
    lineHeight: '1.9rem',
    color: theme.palette.grey[400],
    '&.Mui-error': {
      color: theme.palette.error.main
    }
  },
  '& .MuiStack-root': {
    paddingTop: 0
  }
}));

export { StyledDatePickerContainer };
