// ==============================|| OVERRIDES - ICON BUTTON ||============================== //

import { ThemeOptions } from '@mui/material';

export default function IconButton(theme: ThemeOptions) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'all',
            '& svg path': { stroke: theme.palette?.grey?.[300] }
          }
        }
        // root: {
        //   borderRadius: 4
        // },
        // sizeLarge: {
        //   width: theme.spacing && theme?.spacing,
        //   height: theme.spacing && theme?.spacing,
        //   fontSize: '1.25rem'
        // },
        // sizeMedium: {
        //   width: theme.spacing && theme?.spacing,
        //   height: theme.spacing && theme?.spacing,
        //   fontSize: '1rem'
        // },
        // sizeSmall: {
        //   width: theme.spacing && theme?.spacing,
        //   height: theme.spacing && theme?.spacing,
        //   fontSize: '0.75rem'
        // }
      }
    }
  };
}
