import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

const StyledSelectSubLabel = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 500,
  color: theme.palette.grey[400],
  textTransform: 'capitalize'
}));

export default StyledSelectSubLabel;
