import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { APP_ROUTES } from '../../../../shared/config/routes-config';
import { UserRole } from '../../../../shared/enums/user-role.enum';
import { RouteConfig } from '../../../../shared/interfaces/routes-config.interface';
import DefaultLayout from '../../../../shared/layouts/default/default.layout';

const TransactionsListContainer = React.lazy(
  () => import('../../containers/transaction-list/transaction-list.container')
);

const TransactionsAddContainer = React.lazy(
  () => import('../../containers/transaction-add/transaction-add.container')
);

const TransactionDetailsContainer = React.lazy(
  () => import('../../containers/transaction-details/transaction-details.container')
);

const TransactionEditContainer = React.lazy(
  () => import('../../containers/transaction-edit/transaction-edit.container')
);

export const TransactionsRoutesConfig: RouteObject[] = [
  {
    index: true,
    element: (
      <Navigate to={(APP_ROUTES.TRANSACTIONS_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH} />
    )
  },
  {
    path: (APP_ROUTES.TRANSACTIONS_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[UserRole.ADMIN, UserRole.FINANCE_OFFICER]}
          component={TransactionsListContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.TRANSACTIONS_MODULE.CHILDREN as RouteConfig).ADD.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          addLayout
          authenticationRequired={true}
          permittedList={[UserRole.ADMIN, UserRole.FINANCE_OFFICER]}
          component={TransactionsAddContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.TRANSACTIONS_MODULE.CHILDREN as RouteConfig).DETAILS.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          authenticationRequired={true}
          permittedList={[UserRole.ADMIN, UserRole.FINANCE_OFFICER]}
          component={TransactionDetailsContainer}
        />
      </React.Suspense>
    )
  },
  {
    path: (APP_ROUTES.TRANSACTIONS_MODULE.CHILDREN as RouteConfig).EDIT.FULL_PATH,
    element: (
      <React.Suspense fallback={<div>Loading...</div>}>
        <DefaultLayout
          addLayout
          authenticationRequired={true}
          permittedList={[UserRole.ADMIN, UserRole.FINANCE_OFFICER]}
          component={TransactionEditContainer}
        />
      </React.Suspense>
    )
  }
];
