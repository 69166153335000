import { Grid, useTheme } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import PageHeader from '../../../../shared/components/group-ui/page-header/page-header.component';
import FormSectionTitle from '../../../../shared/components/core-ui/form/form-section-title/form-section-title.component';
import { FinanceApplicationReportUIFilters } from '../../../../shared/ui-models/finance-application-report.ui-model';
import {
  initialFinanceApplicationsFiltersValues,
  validateFinanceApplicationReportFiltersForm
} from '../../shared/utils/helpers/filters-validation';
import { useAlert } from '../../../../shared/hooks/alert/useAlert';
import Alert from '../../../../shared/components/core-ui/alert/alert.component';
import { useForm } from '../../../../shared/hooks/form/useForm';
import FormDatePicker from '../../../../shared/components/group-ui/form-controls/form-date-picker/form-date-picker.component';
import FormSelect from '../../../../shared/components/group-ui/form-controls/select/select/select.component';
import { convertEnumsToIDLabelArr } from '../../../../shared/services/helpers/format-enums/id-label-format/convert-enum-to-id-label';
import { getAllAgents } from '../../../../shared/services/data/finance-applications/finance-applications.data';
import { getModuleName } from '../../shared/services/finance-applications-reports.service';
import { isErrorInfo } from '../../../../shared/interfaces/error-info.interface';
import FormSelectWithSearch from '../../../../shared/components/group-ui/form-controls/select/select-with-search/select-with-search.component';
import useFinanceApplicationReportStore from '../../shared/store/finance-applications-reports.store';
import Button from '../../../../shared/components/core-ui/button/button.component';
import { APP_ROUTES } from '../../../../shared/config/routes-config';
import { RouteConfig } from '../../../../shared/interfaces/routes-config.interface';
import { mapValuesToQuery } from '../../shared/utils/helpers/utils';
import { FinAppStatus } from '../../../CustomersModule/shared/enums/customer-finance-application.enum';
import { CustomerSourceOptions } from '../../../CustomersModule/shared/enums/customer-personal-data.enums';
import FormMultiSelect from '../../../../shared/components/group-ui/form-controls/select/multi-select/multi-select.component';

function FinanceApplicationReportFilterContainer(): JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();

  const { isOpenAlert, setIsOpenAlert, alertText, showAlert } = useAlert();

  const {
    searchLoading,
    dataLoading,
    financeMerchantSearchQuery,
    financeMerchantSelectOptions,
    carofiAgentsSelectOptions,
    query,
    actions: {
      setSearchLoading,
      setFinanceMerchantSearchQuery,
      getFinanceMerchants,
      setCarofiAgentsSelectOptions,
      setQuery
    }
  } = useFinanceApplicationReportStore();

  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm<FinanceApplicationReportUIFilters>(
      initialFinanceApplicationsFiltersValues,
      false,
      validateFinanceApplicationReportFiltersForm
    );

  const customerSourceItems = useMemo(() => {
    return convertEnumsToIDLabelArr({ ...CustomerSourceOptions });
  }, []);

  useEffect(() => {
    getAllAgents(getModuleName()).then((res) => {
      if (isErrorInfo(res)) {
        showAlert(res.errorMessage);
      } else {
        const carofiAgentsSelectOptions = res.map((user) => ({
          id: user.id ?? '',
          label: user.name ?? ''
        }));
        setCarofiAgentsSelectOptions(carofiAgentsSelectOptions);

        setValues(
          mapValuesToQuery(query.query ?? {}, {
            statusSelectOptions,
            carofiAgentsSelectOptions,
            financeMerchantSelectOptions,
            customerSourceItems
          }) as FinanceApplicationReportUIFilters
        );
      }
    });
  }, []);

  useEffect(() => {
    getFinanceMerchants({ merchantType: 'finance', showAlert: showAlert });
  }, [financeMerchantSearchQuery]);

  useEffect(() => {
    if (!values.startDate && !values.endDate) {
      return;
    }

    validateFinanceApplicationReportFiltersForm(values, errors, setErrors);
  }, [values]);

  const statusSelectOptions = useMemo(() => {
    return convertEnumsToIDLabelArr({ ...FinAppStatus });
  }, []);

  const handleGenerateReport = (): void => {
    const { valid } = validateFinanceApplicationReportFiltersForm(values, errors, setErrors);
    if (!valid) return;

    setQuery(values);

    navigate(
      (APP_ROUTES.REPORTS_MODULE.CHILDREN as RouteConfig).FINANCE_APPLICATIONS_REPORT.FULL_PATH
    );
  };
  return (
    <>
      <PageHeader
        data={{
          title: 'Finance Applications Report',
          description: 'You can generate Financial Application Reports.'
        }}
        components={{
          actionsList: []
        }}
      />

      <Grid container>
        <Grid
          container
          sx={{
            backgroundColor: 'white',
            borderRadius: '1rem',
            p: 3,
            m: '2rem 3rem 0',
            [theme.breakpoints.down('sm')]: {
              textAlign: 'center',
              p: 1,
              m: '1rem 1.5rem 0'
            }
          }}
        >
          <Grid item xs={12}>
            <FormSectionTitle title="Generate Report" />
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} md={4}>
              <FormDatePicker
                data={{
                  name: 'startDate',
                  label: 'Start Date*',
                  value: values.startDate,
                  error: errors.startDate
                }}
                eventHandlers={{ handleInputChange }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormDatePicker
                data={{
                  name: 'endDate',
                  label: 'End Date*',
                  value: values.endDate,
                  error: errors.endDate
                }}
                eventHandlers={{ handleInputChange }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} md={4}>
              <FormMultiSelect
                data={{
                  name: 'status',
                  label: 'Status',
                  placeholder: 'Select status',
                  value: values.status ?? [],
                  error: errors.status,
                  selectOptions: statusSelectOptions,
                  noResultsFoundText: 'No applications found'
                }}
                eventHandlers={{ handleInputChange }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormMultiSelect
                data={{
                  name: 'agent',
                  label: 'Carofi Agent',
                  placeholder: 'Select Agent',
                  value: values.agent ?? [],
                  error: errors.agent,
                  selectOptions: carofiAgentsSelectOptions,
                  noResultsFoundText: 'No Agent found'
                }}
                eventHandlers={{ handleInputChange }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} md={4}>
              <FormSelectWithSearch
                data={{
                  name: 'financialMerchantId',
                  label: 'Financial Merchant',
                  placeholder: 'Select Merchant',
                  searchTextPlaceholder: 'Search for merchants ..',
                  value: values.financialMerchantId ?? null,
                  error: errors.financialMerchantId,
                  selectOptions: financeMerchantSelectOptions,
                  searchLoading: searchLoading,
                  dataLoading: dataLoading,
                  noResultsFoundText: 'No merchants found'
                }}
                eventHandlers={{
                  handleInputChange,
                  setSearchLoading: setSearchLoading,
                  setSearchQuery: setFinanceMerchantSearchQuery
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormMultiSelect
                data={{
                  name: 'source',
                  label: 'Customer Source',
                  placeholder: 'Select source',
                  value: values.source ?? [],
                  error: errors.source,
                  selectOptions: customerSourceItems,
                  noResultsFoundText: 'No source found'
                }}
                eventHandlers={{ handleInputChange }}
              />
            </Grid>
          </Grid>
          <Button
            config={{}}
            eventHandlers={{
              handleClick: handleGenerateReport
            }}
          >
            Generate Report
          </Button>
        </Grid>
      </Grid>

      <Alert
        data={{ text: alertText }}
        config={{ isOpen: isOpenAlert }}
        eventHandlers={{ setOpen: setIsOpenAlert }}
      />
    </>
  );
}

export default FinanceApplicationReportFilterContainer;
