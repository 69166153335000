import { useState } from 'react';

export function useForm<T>(
  initialFValues: T,
  validateOnChange = false,
  validate: (fieldValues: any, errors: any, setErrors: (error: any) => void) => void
) {
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState<any>({});

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });

    if (validateOnChange) validate({ [name]: value }, errors, setErrors);
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange
  };
}
