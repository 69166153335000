import moment from 'moment-timezone';
import { ResponseModel } from '../../interfaces/response-model.interface';
import { CompanyDetailsModel } from '../../models/merchant.model';
import { CompanyDetailsUIModel } from '../../ui-models/merchants.ui-model';

function mapToModel(
  companyDetails: CompanyDetailsUIModel,
  initialValues?: CompanyDetailsUIModel
): CompanyDetailsModel {
  return {
    ...(companyDetails.id && { id: companyDetails.id }),
    ...(companyDetails.merchantType && { merchantType: companyDetails.merchantType }),
    ...(companyDetails.merchantProductStatus && {
      merchantProductStatus: [companyDetails.merchantProductStatus]
    }),
    ...(companyDetails.companyNameEn && {
      companyName: {
        en: companyDetails.companyNameEn,
        ar: (initialValues ? initialValues.companyNameAr : companyDetails.companyNameAr) ?? ''
      }
    }),
    ...(companyDetails.companyNameAr && {
      companyName: {
        en: (initialValues ? initialValues.companyNameEn : companyDetails.companyNameEn) ?? '',
        ar: companyDetails.companyNameAr
      }
    }),
    ...(companyDetails.companyLegalNameEn && {
      legalName: {
        en: companyDetails.companyLegalNameEn,
        ar:
          (initialValues ? initialValues.companyLegalNameAr : companyDetails.companyLegalNameAr) ??
          ''
      }
    }),
    ...(companyDetails.companyLegalNameAr && {
      legalName: {
        en:
          (initialValues ? initialValues.companyLegalNameAr : companyDetails.companyLegalNameAr) ??
          '',
        ar: companyDetails.companyLegalNameAr
      }
    }),
    ...(companyDetails.companyWebsite && { companyWebsite: companyDetails.companyWebsite }),
    ...(companyDetails.ceoNameEn && {
      ceoName: {
        en: companyDetails.ceoNameEn,
        ar: (initialValues ? initialValues.ceoNameAr : companyDetails.ceoNameAr) ?? ''
      }
    }),
    ...(companyDetails.ceoNameAr && {
      ceoName: {
        en: (initialValues ? initialValues.ceoNameEn : companyDetails.ceoNameEn) ?? '',
        ar: companyDetails.ceoNameAr
      }
    }),
    ...(companyDetails.ceoId && { ceoId: companyDetails.ceoId }),
    ...(companyDetails.merchantCityId?.id && {
      merchantCityId: companyDetails.merchantCityId.id
    }),
    ...(companyDetails.merchantAreaId?.id && {
      merchantAreaId: companyDetails.merchantAreaId.id
    }),
    ...(companyDetails.merchantAddressEn && {
      merchantAddress: {
        en: companyDetails.merchantAddressEn,
        ar:
          (initialValues ? initialValues.merchantAddressAr : companyDetails.merchantAddressAr) ?? ''
      }
    }),
    ...(companyDetails.merchantAddressAr && {
      merchantAddress: {
        en:
          (initialValues ? initialValues.merchantAddressEn : companyDetails.merchantAddressEn) ??
          '',
        ar: companyDetails.merchantAddressAr
      }
    }),
    ...(companyDetails.commercialRegisterType && {
      commercialRegisterType: companyDetails.commercialRegisterType
    }),
    ...(companyDetails.commercialRegisterNumber && {
      commercialRegisterNumber: Number(companyDetails.commercialRegisterNumber)
    }),
    ...(companyDetails.commercialRegisterIssuanceDate && {
      commercialRegisterIssuanceDate: moment(
        companyDetails.commercialRegisterIssuanceDate
      ).toISOString()
    }),
    ...(companyDetails.commercialRegisterStartDate && {
      commercialRegisterStartDate: moment(companyDetails.commercialRegisterStartDate).toISOString()
    }),
    ...(companyDetails.commercialRegisterExpiryDate && {
      commercialRegisterExpiryDate: moment(
        companyDetails.commercialRegisterExpiryDate
      ).toISOString()
    }),
    ...(companyDetails.companyTaxId && { companyTaxId: companyDetails.companyTaxId }),
    ...(companyDetails.whtExemption !== undefined && { whtExemption: companyDetails.whtExemption }),
    ...(companyDetails.whtExpiredDate !== undefined && {
      whtExpiredDate: moment(companyDetails.whtExpiredDate).toISOString()
    }),
    ...(companyDetails.bankName && { bankName: companyDetails.bankName }),
    ...(companyDetails.accountNumber && { accountNumber: Number(companyDetails.accountNumber) }),
    ...(companyDetails.iban && { iban: companyDetails.iban }),
    ...(companyDetails.companyStatus && { companyStatus: companyDetails.companyStatus }),
    ...(companyDetails.isFeatured !== null && { isFeatured: companyDetails.isFeatured }),
    ...(companyDetails.financeMerchantOrganizationType && {
      financeMerchantOrganizationType: companyDetails.financeMerchantOrganizationType
    }),
    ...(companyDetails.contactEmails && { contactEmails: companyDetails.contactEmails })
  };
}

function mapToUI(merchant: CompanyDetailsModel): CompanyDetailsUIModel {
  return {
    merchantType: merchant.merchantType,
    merchantProductStatus: merchant.merchantProductStatus?.[0],
    companyNameEn: merchant.companyName?.en,
    companyNameAr: merchant.companyName?.ar,
    companyLegalNameEn: merchant.legalName?.en,
    companyLegalNameAr: merchant.legalName?.ar,
    companyWebsite: merchant.companyWebsite,
    companyLogo: { id: merchant.key ?? '', url: merchant.url ?? '' } as unknown as {
      file: string;
      dataURL: string;
    }[],
    ceoNameEn: merchant.ceoName?.en,
    ceoNameAr: merchant.ceoName?.ar,
    ceoId: merchant.ceoId,
    merchantCityId: {
      id: merchant.merchantCityId ?? '',
      label: `${merchant.merchantCity?.en} - ${merchant.merchantCity?.ar}`
    },
    merchantAreaId: {
      id: merchant.merchantAreaId ?? '',
      label: `${merchant.merchantArea?.en} - ${merchant.merchantArea?.ar}`
    },
    merchantAddressEn: merchant.merchantAddress?.en,
    merchantAddressAr: merchant.merchantAddress?.ar,
    commercialRegisterType: merchant.commercialRegisterType,
    commercialRegisterNumber: merchant.commercialRegisterNumber,
    commercialRegisterIssuanceDate: moment(merchant.commercialRegisterIssuanceDate).tz(
      'Africa/Cairo'
    ),
    commercialRegisterStartDate: moment(merchant.commercialRegisterStartDate).tz('Africa/Cairo'),
    commercialRegisterExpiryDate: moment(merchant.commercialRegisterExpiryDate).tz('Africa/Cairo'),
    companyTaxId: merchant.companyTaxId,
    whtExemption: merchant.whtExemption,
    whtExpiredDate: merchant.whtExpiredDate
      ? moment(merchant.whtExpiredDate).tz('Africa/Cairo')
      : null,
    bankName: merchant.bankName,
    accountNumber: merchant.accountNumber,
    iban: merchant.iban,
    companyStatus: merchant.companyStatus,
    isFeatured: merchant.isFeatured,
    financeMerchantOrganizationType: merchant.financeMerchantOrganizationType,
    contactEmails: merchant.contactEmails
  };
}

const companyDetailsMapper = { mapToModel, mapToUI };

export default companyDetailsMapper;
