import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import { autocompleteClasses } from '@mui/material/Autocomplete';

export const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none'
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0
  }
}));

export const StyledPopper = styled(Popper)(({ theme }) => ({
  boxShadow: '0 8px 24px rgba(149, 157, 165, 0.2)',
  borderRadius: 8,
  zIndex: theme.zIndex.modal,
  backgroundColor: '#fff',
  '& .MuiAutocomplete-popper': {
    width: 'auto !important',
    '& .MuiAutocomplete-option': {
      height: '5.4rem',
      padding: '1.6rem 2rem !important',
      borderBottom: 'none !important',
      fontWeight: 400,
      fontSize: '1.6rem',
      lineHeight: '2.2rem',
      color: theme.palette.text.primary
    },
    '& .MuiAutocomplete-option.Mui-focused': {
      backgroundColor: `${theme.palette.action.hover} !important`
    }
  }
}));

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

export function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}
