import { LanguageField } from '../types/shared-types/language-field.type';

enum MotorTypes {
  PETROL = 'petrol',
  ELECTRIC = 'electric',
  HYBRID = 'hybrid',
  GAS = 'gas',
  DIESEL = 'diesel'
}

function getMotorTypesLang(motorType: MotorTypes): LanguageField {
  switch (motorType) {
    case MotorTypes.PETROL:
      return { en: motorType, ar: 'بنزين' };
    case MotorTypes.ELECTRIC:
      return { en: motorType, ar: 'كهرباء' };
    case MotorTypes.HYBRID:
      return { en: motorType, ar: 'هجين' };
    case MotorTypes.GAS:
      return { en: motorType, ar: 'غاز' };
    case MotorTypes.DIESEL:
      return { en: motorType, ar: 'ديزل' };
  }
}

enum TransmissionTypes {
  AUTOMATIC = 'automatic',
  MANUAL = 'manual',
  STEPTRONIC = 'steptronic'
}

function getTransmissionTypesLang(transmissionType: TransmissionTypes): LanguageField {
  switch (transmissionType) {
    case TransmissionTypes.AUTOMATIC:
      return { en: transmissionType, ar: 'أوتوماتيك' };
    case TransmissionTypes.MANUAL:
      return { en: transmissionType, ar: 'مانيول' };
    case TransmissionTypes.STEPTRONIC:
      return { en: transmissionType, ar: 'ستب ترونيك' };
  }
}

export { MotorTypes, getMotorTypesLang, TransmissionTypes, getTransmissionTypesLang };
