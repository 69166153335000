// ==============================|| OVERRIDES - BUTTON ||============================== //

import { ThemeOptions } from '@mui/material';

export default function Button(theme: ThemeOptions) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
          borderRadius: '8px'
        },
        contained: {
          fontWeight: 700,
          fontSize: '1.6rem',
          boxShadow: 'none'
        },
        outlined: {
          // @ts-ignore
          border: `1px solid ${theme.palette?.primary.main}`,
          // @ts-ignore
          color: theme.palette?.primary.main
        },
        text: {
          fontSize: '1.6rem'
        }
      }
    }
  };
}
