import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../interfaces/error-info.interface';
import { FinanceApplicationReportResponseModel } from '../../../interfaces/response-model.interface';

import errorHelper from '../../helpers/error-helper/error-helper.service';
import { financeApplicationsReportApi } from '../../apis/finance-applications-report-apis/finance-applications-report-api.service';
import { FinanceApplicationsReportModel } from '../../../models/finance-application-report.model';
import { FinanceApplicationsReportUiModel } from '../../../ui-models/finance-application-report.ui-model';
import { FinanceApplicationReportTablePayload } from '../../../../Modules/ReportsModule/shared/types/finance-application-report-payload.type';
import financeApplicationsReportMapper from '../../../mappers/finance-applications-report/finance-applications-report.mapper';
import { exportData } from '../../../../Modules/ReportsModule/shared/utils/helpers/utils';

export async function searchFinanceApplicationReport(
  reportPayload: FinanceApplicationReportTablePayload,
  containerName: string
): Promise<FinanceApplicationReportResponseModel<FinanceApplicationsReportUiModel[]> | ErrorInfo> {
  const response: ApiResponse<
    FinanceApplicationReportResponseModel<FinanceApplicationsReportModel[]>,
    ErrorInfo
  > = await financeApplicationsReportApi.searchFinanceApplicationsReport(reportPayload);

  if (response.ok) {
    const { data } = response;
    if (data?.data) {
      return {
        ...data,
        data: financeApplicationsReportMapper.mapToUi(data.data),
        total_count: data.total_count ?? 0,
        totalFinanceAmount: data.totalFinanceAmount ?? 0
      };
    } else {
      const error: ErrorInfo = errorHelper(
        0,
        '',
        containerName,
        financeApplicationsReportApi.searchFinanceApplicationsReport?.name || ''
      );
      return error;
    }
  } else {
    const error: ErrorInfo = errorHelper(
      Number(response.status),
      response.data?.errorCode ? String(response.data.errorCode || '') : response.problem,
      containerName,
      financeApplicationsReportApi.searchFinanceApplicationsReport?.name || ''
    );
    return error;
  }
}

export async function exportFinanceApplicationReport(
  payload: Pick<FinanceApplicationReportTablePayload, 'query' | 'sort'>,
  containerName: string
): Promise<ErrorInfo | undefined> {
  return exportData<FinanceApplicationReportTablePayload>(
    financeApplicationsReportApi.exportFinanceApplicationsReport,
    payload,
    containerName,
    'finance-application-report'
  );
}
