import { FC } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import { useTheme } from '@mui/material/styles';

import StyledFormControl from '../../../../core-ui/form/styled-components/styled-form-control';
import { ChangeEvent } from '../../../../../types/shared-types/change-event.type';
import { StyledIconButton } from '../styled-select-componenets/styled-select-icon-btn';
import { SelectOptionsProps } from '../../../../../types/shared-types/select.type';
import { ReactComponent as ArrowDown } from '../../../../../../assets/svgs/dropdown/down-arrow.icon.svg';

interface MultiSelectProps {
  data: {
    name: string;
    label: string;
    placeholder: string;
    value: SelectOptionsProps[];
    error: any;
    selectOptions: SelectOptionsProps[];
    noResultsFoundText: string;
  };
  eventHandlers: {
    handleInputChange: (e: ChangeEvent<SelectOptionsProps[]>) => void;
  };
}

const FormMultiSelect: FC<MultiSelectProps> = ({ data, eventHandlers }) => {
  const { name, label, placeholder, value, error = null, selectOptions, noResultsFoundText } = data;
  const { handleInputChange } = eventHandlers;

  const theme = useTheme();

  const getFieldEndAdornmentIcon = () => {
    return (
      <>
        {value.length > 0 && (
          <StyledIconButton
            onClick={(event) => {
              event.stopPropagation();
              handleInputChange({ target: { name, value: [] } });
            }}
          >
            <CloseIcon fontSize="small" />
          </StyledIconButton>
        )}

        <ArrowDown />
      </>
    );
  };

  return (
    <StyledFormControl>
      <FormLabel>{label}</FormLabel>
      <Autocomplete
        multiple
        limitTags={1}
        getLimitTagsText={(num) => `+${num} ${label}`}
        value={value}
        options={selectOptions}
        disableCloseOnSelect={true}
        getOptionLabel={(option) => (option.label ? option.label : '')}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        noOptionsText={noResultsFoundText}
        onChange={(event, newValue, reason) => {
          if (
            event.type === 'keydown' &&
            (event as React.KeyboardEvent).key === 'Backspace' &&
            reason === 'removeOption'
          ) {
            return;
          }
          handleInputChange({ target: { name, value: newValue } });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            placeholder={value && value?.length > 0 ? '' : placeholder}
            label=""
            fullWidth
            error={Boolean(error)}
            autoComplete="off"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <Box sx={{ maxHeight: '80px', overflowY: 'auto' }}>
                  {params.InputProps.startAdornment}
                </Box>
              ),
              endAdornment: getFieldEndAdornmentIcon()
            }}
            sx={{
              '& .MuiInputBase-input': {
                color: theme.palette.grey[400],
                p: '8px 8px !important'
              },
              '& .MuiOutlinedInput-root': {
                pr: '12px !important',
                border: error && `1px solid ${theme.palette.error.main}`
              }
            }}
          />
        )}
      />

      <FormHelperText error>{error ? error : ' '}</FormHelperText>
    </StyledFormControl>
  );
};

export default FormMultiSelect;
