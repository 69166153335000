import { ApiResponse } from 'apisauce';
import {
  DeletedMerchantDocPayload,
  MerchantTablePayload,
  MerchantUsersPayload
} from '../../../Modules/MerchatsModule/shared/types/merchant-auto-payload.type';
import { ErrorInfo } from '../../interfaces/error-info.interface';
import { ResponseModel } from '../../interfaces/response-model.interface';
import branchesMapper from '../../mappers/merchant-mappers/branches.mapper';
import companyDetailsMapper from '../../mappers/merchant-mappers/company-details.mapper';
import contactPersonMapper from '../../mappers/merchant-mappers/contact-person.mapper';
import merchantsAutoTableMapper from '../../mappers/merchant-mappers/merchants-auto-table.mapper';
import {
  BranchDataModel,
  CompanyContactPersonModel,
  CompanyDetailsModel,
  GetMerchantDocumentsModel,
  MerchantDocumentsDataModel,
  MerchantModel,
  MerchantUserModel,
  MerchantUsersModel
} from '../../models/merchant.model';
import {
  BranchDataUIModel,
  CompanyContactPersonUIModel,
  CompanyDetailsUIModel,
  MerchantDocsUIModel,
  MerchantDocumentsDataUIModel,
  MerchantUIModel
} from '../../ui-models/merchants.ui-model';
import { merchantApi } from '../apis/merchant-api.service';
import errorHelper from '../helpers/error-helper/error-helper.service';
import { DocsDataModel } from '../../types/shared-types/documents.type';
import merchantDocsMapper from '../../mappers/merchant-mappers/company-docs.mapper';

export async function createCompanyDetails(
  companyDetails: CompanyDetailsUIModel,
  containerName: string
): Promise<ResponseModel<{ id: string }> | ErrorInfo> {
  const mappedData = companyDetailsMapper.mapToModel(companyDetails);
  return merchantApi
    .createCompanyDetails(mappedData)
    .then((response: ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.createCompanyDetails as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.createCompanyDetails as any).displayName
        );

        return error;
      }
    });
}

export async function createCompanyLogo(
  companyId: string,
  asset: string,
  containerName: string
): Promise<{ message: string } | ErrorInfo> {
  const data = new FormData();

  data.append('companyId', companyId);
  data.append('asset', asset);

  return merchantApi
    .createCompanyLogo(data)
    .then((response: ApiResponse<{ message: string }, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.createCompanyLogo as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.createCompanyLogo as any).displayName
        );
        return error;
      }
    });
}

export async function createCompanyContactPerson(
  merchantId: string,
  contactPersonDetails: CompanyContactPersonUIModel,
  containerName: string
): Promise<ResponseModel<{ id: string }> | ErrorInfo> {
  const mappedData = contactPersonMapper.mapToModel(merchantId, contactPersonDetails);
  return merchantApi
    .createCompanyContactPerson(mappedData)
    .then((response: ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.createCompanyContactPerson as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.createCompanyContactPerson as any).displayName
        );

        return error;
      }
    });
}

export async function createBranchData(
  merchantId: string,
  branches: BranchDataUIModel[],
  containerName: string
): Promise<ResponseModel<{ ids: string[] }> | ErrorInfo> {
  const mappedData = {
    companyId: merchantId,
    data: branches.map((branch) => branchesMapper.mapToModel(branch))
  };

  return merchantApi
    .createBranchData(mappedData)
    .then((response: ApiResponse<ResponseModel<{ ids: string[] }>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.createBranchData as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.createBranchData as any).displayName
        );

        return error;
      }
    });
}

export async function getCompanyById(
  merchantId: string,
  containerName: string
): Promise<MerchantUIModel | ErrorInfo> {
  return merchantApi
    .getCompanyById(merchantId)
    .then((response: ApiResponse<ResponseModel<MerchantModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          const mappedData: MerchantUIModel = {
            companyDetails: companyDetailsMapper.mapToUI(data.data),
            contactPerson: contactPersonMapper.mapToUI(data)
          };
          return mappedData;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.getCompanyById as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.getCompanyById as any).displayName
        );

        return error;
      }
    });
}

export async function getCompanyBranches(
  merchantId: string,
  containerName: string
): Promise<ResponseModel<BranchDataUIModel[]> | ErrorInfo> {
  return merchantApi
    .getCompanyBranches(merchantId)
    .then((response: ApiResponse<ResponseModel<BranchDataModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          const mappedData = {
            ...data,
            data: data.data.map((branch) => branchesMapper.mapToUI(branch))
          };
          return mappedData;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.getCompanyBranches as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.getCompanyBranches as any).displayName
        );

        return error;
      }
    });
}

export async function updateCompanyDetails(
  companyDetails: CompanyDetailsUIModel,
  companyDetailsValues: CompanyDetailsUIModel,
  containerName: string
): Promise<ResponseModel<CompanyDetailsModel> | ErrorInfo> {
  const mappedData = companyDetailsMapper.mapToModel(companyDetails, companyDetailsValues);
  return merchantApi
    .updateCompanyDetails(mappedData)
    .then((response: ApiResponse<ResponseModel<CompanyDetailsModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.updateCompanyDetails as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.updateCompanyDetails as any).displayName
        );

        return error;
      }
    });
}

export async function updateCompanyContactPerson(
  merchantId: string,
  contactPersonDetails: CompanyContactPersonUIModel,
  contactPersonDetailsValues: CompanyContactPersonUIModel,
  containerName: string
): Promise<ResponseModel<CompanyContactPersonModel> | ErrorInfo> {
  const mappedData = contactPersonMapper.mapToModel(
    merchantId,
    contactPersonDetails,
    contactPersonDetailsValues
  );
  return merchantApi
    .updateCompanyContactPerson(mappedData)
    .then((response: ApiResponse<ResponseModel<CompanyContactPersonModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.updateCompanyContactPerson as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.updateCompanyContactPerson as any).displayName
        );

        return error;
      }
    });
}

export async function updateBranchData(
  branch: BranchDataUIModel,
  initialBranch: BranchDataUIModel,
  containerName: string
): Promise<ResponseModel<BranchDataModel> | ErrorInfo> {
  const mappedData = branchesMapper.mapToModel(branch, initialBranch);
  return merchantApi
    .updateBranchData(mappedData)
    .then((response: ApiResponse<ResponseModel<BranchDataModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.updateBranchData as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.updateBranchData as any).displayName
        );

        return error;
      }
    });
}

export async function deleteBranch(
  id: string,
  containerName: string
): Promise<{ message: string } | ErrorInfo> {
  return merchantApi
    .deleteBranch(id)
    .then((response: ApiResponse<{ message: string }, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.deleteBranch as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.deleteBranch as any).displayName
        );

        return error;
      }
    });
}

export async function updateCompanyStatus(
  id: string,
  companyStatus: 'active' | 'disabled',
  containerName: string
): Promise<ResponseModel<MerchantModel> | ErrorInfo> {
  return merchantApi
    .updateCompanyStatus(id, companyStatus)
    .then((response: ApiResponse<ResponseModel<MerchantModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.updateCompanyStatus as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.updateCompanyStatus as any).displayName
        );

        return error;
      }
    });
}

export async function searchCompanies(
  searchCompanies: MerchantTablePayload,
  containerName: string
): Promise<ResponseModel<CompanyDetailsUIModel[]> | ErrorInfo> {
  return merchantApi
    .searchCompanies(searchCompanies)
    .then((response: ApiResponse<ResponseModel<MerchantModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          const mappedData = {
            ...data,
            data: data.data.map((data) => merchantsAutoTableMapper.mapToUI(data)),
            total_count: data.total_count
          };
          return mappedData;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.searchCompanies as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.searchCompanies as any).name
        );

        return error;
      }
    });
}

export async function createCompanyUser(
  user: MerchantUserModel,
  containerName: string
): Promise<ResponseModel<{ id: string }> | ErrorInfo> {
  return merchantApi
    .createCompanyUser(user)
    .then((response: ApiResponse<ResponseModel<{ id: string }>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.createCompanyUser as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.createCompanyUser as any).name
        );

        return error;
      }
    });
}

export async function searchCompanyUsers(
  searchCompanyUsers: MerchantUsersPayload,
  containerName: string
): Promise<ResponseModel<MerchantUsersModel[]> | ErrorInfo> {
  return merchantApi
    .searchCompanyUsers(searchCompanyUsers)
    .then((response: ApiResponse<ResponseModel<MerchantUsersModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.searchCompanyUsers as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.searchCompanyUsers as any).name
        );

        return error;
      }
    });
}

export async function deleteCompanyUser(
  id: string,
  containerName: string
): Promise<{ message: string } | ErrorInfo> {
  return merchantApi
    .deleteCompanyUser(id)
    .then((response: ApiResponse<{ message: string }, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.deleteCompanyUser as any).displayName
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.deleteCompanyUser as any).displayName
        );

        return error;
      }
    });
}

export async function createMerchantDocuments(
  merchantDocuments: MerchantDocumentsDataUIModel,
  containerName: string
): Promise<{ message: string } | ErrorInfo> {
  const data = new FormData();

  const mappedData = merchantDocsMapper.mapToModel(merchantDocuments);

  data.append('companyId', mappedData.companyId);
  let hasFiles = false;
  for (const property in mappedData) {
    if (property !== 'companyId') {
      (mappedData[property as keyof MerchantDocsUIModel] as DocsDataModel[]).forEach(
        (file: DocsDataModel) => {
          if ((file as unknown as Blob).size) {
            hasFiles = true;
            data.append(property, file as unknown as Blob);
          }
        }
      );
    }
  }
  if (!hasFiles) return { message: '' };

  return merchantApi
    .createMerchantDocuments(data)
    .then((response: ApiResponse<{ message: string }, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          return data;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.createMerchantDocuments as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.createMerchantDocuments as any).name
        );
        return error;
      }
    });
}

export async function getMerchantDocumentsById(
  id: string,
  containerName: string
): Promise<MerchantDocumentsDataUIModel | ErrorInfo> {
  return merchantApi
    .getMerchantDocumentsById(id)
    .then((response: ApiResponse<ResponseModel<GetMerchantDocumentsModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data) {
          const mappedData = merchantDocsMapper.mapToUI(data.data);
          return mappedData;
        } else {
          const error: ErrorInfo = errorHelper(
            0,
            '',
            containerName,
            (merchantApi.getMerchantDocumentsById as any).name
          );
          return error;
        }
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.getMerchantDocumentsById as any).name
        );
        return error;
      }
    });
}

export async function deleteMerchantDocument(
  doc: DeletedMerchantDocPayload,
  containerName: string
): Promise<ResponseModel<MerchantDocumentsDataModel> | ErrorInfo | undefined> {
  return merchantApi
    .deleteMerchantDocument(doc)
    .then((response: ApiResponse<ResponseModel<MerchantDocumentsDataModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        return data;
      } else {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (merchantApi.deleteMerchantDocument as any).name
        );
        return error;
      }
    });
}
