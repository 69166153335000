// @ts-nocheck
import { ThemeOptions } from '@mui/material';

export default function TextField(theme: ThemeOptions) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          '& input[type=number]::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0
          },
          '& input[type=number]::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0
          },
          '& .MuiInputBase-root': {
            marginTop: '4px'
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            '& .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${theme.palette.grey[200]}`
            },
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.palette.primary.main}`
              }
            },
            '&:hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.palette.primary.main}`
              }
            },
            '& .MuiOutlinedInput-input': {
              color: theme.palette.grey[400],
              fontSize: '1.6rem',
              fontWeight: 400,
              lineHeight: '2.2rem',
              padding: '17px 16px'
            },
            '&.Mui-disabled': {
              backgroundColor: `${theme.palette.grey[100]}`,
              cursor: 'not-allowed',
              '& .MuiOutlinedInput-input': { cursor: 'not-allowed' },
              '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${theme.palette?.grey[200]}`
              },
              '&:hover': {
                '& .MuiOutlinedInput-notchedOutline': {
                  border: `1px solid ${theme.palette.grey[200]}`
                }
              }
            }
          }
        }
      }
    }
  };
}
