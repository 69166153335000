import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';

const SearchTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  paddingLeft: '1.5rem',
  width: '92%',
  background: '#F3F5FA',
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: '1.2rem',
  margin: '12px 10px 12px',
  height: '4rem',
  input: {
    color: theme.palette.grey[400],
    fontWeight: 400,
    fontSize: '1.4rem',
    lineHeight: '1.9rem',
    padding: '4px 0 4px',
    '&::placeholder': {
      opacity: '0.7'
    }
  }
}));

export default SearchTextField;
